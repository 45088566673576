import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField, Typography } from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';
import { serverUrl } from "../config";

const EditTripModal = ({ trip, onUpdateTrip }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(trip.comment);
  const [deleteTripOpen, setDeleteTripOpen] = useState(false);

  useEffect(() => {

  }, []);

  const toggleDialog = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const handleSubmit = async () => {
    const newRoute = {
      _id: trip._id,
      comment: comment
    };

    const token = localStorage.getItem('token');
    axios.put(`${serverUrl}/api/routes`, newRoute, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setOpen(false);
        onUpdateTrip(response.data);
        alert('Рейс змінено!');
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.delete(`${serverUrl}/api/routes/${trip._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }

    setDeleteTripOpen(false);
    setOpen(false);
    onUpdateTrip(null);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={toggleDialog}
        title='Редагувати рейс'>
        <FaEdit />
        <Typography variant='caption' paddingLeft={1}>
        </Typography>
      </Button>

      <Dialog open={open} onClose={toggleDialog} >
        <DialogTitle>Редагування рейса</DialogTitle>
        <DialogContent>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { setDeleteTripOpen(true); }}>
              Видалити рейс
            </Button>
            <Dialog
              open={deleteTripOpen}
              onClose={() => { setDeleteTripOpen(!deleteTripOpen) }}>
              <DialogContent>
                <DialogContentText>
                  Підтвердіть видалення рейса. Увага: рейс в 1С треба видалити самостійно!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => { handleDelete(); setDeleteTripOpen(!deleteTripOpen) }}>
                  Видалити
                </Button>
                <Button
                  variant="contained"
                  onClick={() => { setDeleteTripOpen(!deleteTripOpen) }}>
                  Відмінити
                </Button>
              </DialogActions>
            </Dialog>

            <h5>Коментар для водія:</h5>
            <TextField
              multiline
              value={comment}
              onChange={(event) => setComment(event.target.value)}>
            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => { toggleDialog(event); handleSubmit(); }}>
            Зберегти
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={toggleDialog}>
            Відмінити
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditTripModal;
