import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import { FaArrowCircleDown, FaArrowCircleUp, FaMoneyCheck, FaRuler, FaTags, FaTrailer, FaTruck } from "react-icons/fa";
import { formatDateWithoutYear } from "../../utils/dateUtils";
import Person from "../Person";
import CheckpointsList from "../CheckpointList";
import MapWithRoute from "../MapWithRoute";


const ClientTripDetailsDialog = ({ open, onClose, trip }) => {

    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xl"
    >
        <DialogTitle>Інформація по рейсу</DialogTitle>
        <DialogContent>
            <Grid container columnSpacing={1}>
                <Grid item sm={4} xs={12}>
                    <Divider>
                        <Chip style={{ justifyContent: 'center' }} color="primary" label='Основна інформація' />
                    </Divider>
                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs={2} paddingLeft={'5px'} >
                            <Typography variant="body2" component="div" lineHeight={1}>
                                <small>{formatDateWithoutYear(trip.load_date)}</small>
                            </Typography>

                        </Grid>
                        <Grid item xs={10} >
                            <Typography variant="body2" color="text.primary" lineHeight={1} pb={1}>
                                <FaArrowCircleDown style={{ marginRight: '4px', color: '#ea96a5' }} />
                                Завантаження
                            </Typography>
                            <Typography
                                fontFamily={'Roboto'}
                                variant="body2"
                                color="text.secondary"
                                paddingRight={1}
                                lineHeight={1}
                            >
                                {`${trip.point_load.city}, 
                        ${trip.point_load.street} ${trip.point_load.building},
                         ${trip.point_load.region}, ${trip.point_load.country}`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs={2} paddingLeft={'5px'}>
                            <Typography variant="body2" component="div" lineHeight={1}>
                                <small>{formatDateWithoutYear(trip.unload_date)}</small>
                            </Typography>

                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" color="text.primary" lineHeight={1} pb={1}>
                                <FaArrowCircleUp style={{ marginRight: '4px', color: '#ea96a5' }} />
                                Розвантаження
                            </Typography>
                            <Typography
                                fontFamily={'Roboto'}
                                variant="body2"
                                color="text.secondary"
                                paddingRight={1}
                                lineHeight={1}>
                                {`${trip.point_unload.city}, 
                        ${trip.point_unload.street} 
                        ${trip.point_unload.building}, 
                        ${trip.point_unload.region}, 
                        ${trip.point_unload.country}`}
                            </Typography>
                        </Grid>
                        <Grid container direction={'row'} paddingTop={'10px'} justifyContent={
                            'space-evenly'
                        }>

                            <Grid item>
                                <Typography variant="body2">
                                    <FaRuler /> {trip.distance} км
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} justifyContent={'space-evenly'} paddingTop={1}>
                        <Grid item alignSelf={'center'}>
                            <Person person={trip.client} role={'Клієнт'} />
                        </Grid>
                        <Grid item paddingBottom={1} >
                            <Person person={trip.logist} role={'Логіст'} />
                        </Grid>
                    </Grid>
                    <Divider>
                        <Chip color="primary" label="Дані про водія та авто" />
                    </Divider>
                    <Grid container justifyContent={'space-evenly'} paddingTop={'5px'}>
                        <Grid item ><Person person={trip.driver} role={'Водій'} /></Grid>
                    </Grid>

                    <Grid container justifyContent="space-evenly">

                        <Grid item>
                            <Typography variant="caption">
                                <FaTruck /> {trip.truck.number}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                <FaTrailer /> {trip.trailer.number}
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Divider>
                        <Chip style={{ justifyContent: 'center' }} color="primary" label='Статуси рейса' />
                    </Divider>
                    <Box overflow={'auto'} maxHeight={400}>
                        <CheckpointsList checkpoints={trip.checkpoints} isShowGPS={true} />
                    </Box>
                </Grid>
                <Grid item sm={5} xs={12} maxHeight={400} minHeight={420} overflow={'hidden'}>
                    <Divider>
                        <Chip style={{ justifyContent: 'center' }} color="primary" label='Мапа рейса' />
                    </Divider>
                    <MapWithRoute trip={trip} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Закрити
            </Button>
        </DialogActions>
    </Dialog>
}

export default ClientTripDetailsDialog;