import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, List, ListItemButton, Paper, Typography, useTheme } from '@mui/material';
import "../styles/pagination.css";
import UsersTable from "../components/UsersTable/UsersTable";
import { FaPlus, FaPlusCircle } from "react-icons/fa";
import EditUserDialog from "../components/UsersTable/EditUserDialog";
import VehiclesTable from "../components/VehiclesTable/VehiclesTable";

const Settings = () => {
    const theme = useTheme();

    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory(null);
    }

    const handleSubcategoryClick = (subcategory) => {
        console.log('subcategory', subcategory)
        setSelectedSubcategory(subcategory);
    }

    return <Container maxWidth={'false'} sx={{ paddingTop: 6, height: '100vh' }} >
        <Box
            ml={-3}
            pl={1}
            pt={0}
            height="auto"
            position="fixed"
            width="100%"
            zIndex={100}
            bgcolor={theme.palette.primary.main}
        >
            <Typography variant="h4" color={theme.palette.secondary.main}>
                Налаштування

            </Typography>
        </Box>
        <Grid container pt={7}>
            <Grid item sm={2}>
                <Paper style={{ height: '100%' }}>
                    <Box>
                        <List>
                            <ListItemButton
                                onClick={() => handleCategoryClick('users')}
                                selected={selectedCategory === 'users'}
                            >
                                Користувачі
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => handleCategoryClick('vehicles')}
                                selected={selectedCategory === 'vehicles'}
                            >
                                Транспорт
                            </ListItemButton>
                            {selectedCategory === 'vehicles' && (
                                <List>
                                    <ListItemButton
                                        onClick={() => handleSubcategoryClick('trucks')}
                                        selected={selectedSubcategory === 'trucks'}
                                    >
                                        Вантажівки
                                    </ListItemButton>
                                    <ListItemButton
                                        onClick={() => handleSubcategoryClick('trailers')}
                                        selected={selectedSubcategory === 'trailers'}
                                    >
                                        Причепи
                                    </ListItemButton>
                                </List>
                            )}
                            <ListItemButton
                                onClick={() => handleCategoryClick('news')}
                                selected={selectedCategory === 'news'}
                            >
                                Новини та інструкції
                            </ListItemButton>
                        </List>
                    </Box>
                </Paper>
            </Grid>
            <Grid item sm={10}>
                {selectedCategory === 'users' && (
                    <Paper>
                        <Box>
                            <Grid container alignItems={'center'}>
                                <Grid item>
                                    <Typography variant="h4" pb={1} pl={2}>Користувачі</Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <UsersTable />
                        </Box>
                    </Paper>
                )}
                {selectedCategory === 'vehicles' && selectedSubcategory && (
                    <Paper>
                        <Box>
                            <Grid container alignItems={'center'}>
                                <Grid item>
                                    <Typography variant="h4" pb={1} pl={2}>Транспорт</Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <VehiclesTable vehType={selectedSubcategory}/>
                        </Box>
                    </Paper>
                )}
            </Grid>
        </Grid>
    </Container>
}

export default Settings;