import { FaTruck, FaRoute, FaChartBar, FaSignOutAlt, FaUserFriends, FaTable, FaBell, FaUser, FaRegCommentDots, FaRegSun } from "react-icons/fa";
import { AppBar, Toolbar, IconButton, Box, Button } from "@mui/material";
import { BrowserRouter as Router, Route, NavLink, Routes, Switch } from "react-router-dom";
import Trucks from "./pages/Trucks";
import MyTrips from "./pages/MyTrips";
import Statistics from "./pages/Statistics";
import { AuthProvider, useAuth } from "./AuthContext";
import Login from "./pages/Login";
import Drivers from "./pages/Drivers";
import TrucksTable from "./pages/TrucksTable";
import Settings from "./pages/Settings";
import Layout from "./components/Layout";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import ClientPage from "./pages/ClientPage";
// import News from "./pages/News";

function App() {

  return (
    <AuthProvider>
      <Router>
        <AuthNavigation />
        <AuthRoutes />
      </Router>
    </AuthProvider>
  );
}

const AuthNavigation = () => {
  const { user, logout } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <AppBar position="fixed" className="appBar" style={{ backgroundColor: 'primary' }}>
      <Toolbar>
        {user.role === 'logist' &&
          <>
            <IconButton component={NavLink} to="/trucks" color="secondary" title="Вантажівки">
              <FaTruck size={20} />
            </IconButton>
            <IconButton component={NavLink} to="/trucks-table" color="secondary" title="Таблиця рейсів">
              <FaTable size={20} />
            </IconButton>
            <IconButton component={NavLink} to="/my-trips" color="secondary" title="Мої рейси">
              <FaRoute size={20} />
            </IconButton>
            <IconButton component={NavLink} to="/chats" color="secondary" title="Чат">
              <FaRegCommentDots size={20} />
            </IconButton>
            <IconButton component={NavLink} to="/statistics" color="secondary" title="Статистика">
              <FaChartBar size={20} />
            </IconButton>
            <IconButton component={NavLink} to="/drivers" color="secondary" title="Водії">
              <FaUserFriends size={20} />
            </IconButton>
            <IconButton component={NavLink} to="/settings" color="secondary" title="Налаштування">
              <FaRegSun size={20} />
            </IconButton>
          </>
        }
        {
          user.role == 'client' &&
          <>
           <IconButton component={NavLink} to="/client" color="secondary" title="Рейси">
              <Button variant="contained">
                Рейси
              </Button>
            </IconButton>
          </>
        }

        {/* <IconButton component={NavLink} to="/news" color="secondary" title="Новини">
          <FaRss size={20} />
        </IconButton> */}
        <Box flexGrow={1} />
        {user && (
          <>
            {/* <IconButton color="secondary" title="Сповіщення">
              <FaBell size={20} />
            </IconButton>
            <IconButton color="secondary" title="Профіль">
              <FaUser size={20} />
            </IconButton> */}
            <IconButton onClick={logout} color="secondary" title="Вийти">
              <FaSignOutAlt size={20} />
            </IconButton>
          </>

        )}
      </Toolbar>
    </AppBar>
  );
};


const AuthRoutes = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Завантаження</div>;
  } else if (!user) {
    return <Login />;
  }

  return (
    // <Routes>
    //   <Route path="/trucks" element={<Trucks />} />
    //   <Route path="/trucks-table" element={<TrucksTable />} />
    //   <Route path="/my-trips" element={<MyTrips />} />
    //   <Route path="/statistics" element={<Statistics />} />
    //   <Route path="/drivers" element={<Drivers />} />
    //   <Route path="/settings" element={<Settings />} />
    //   <Route path="*" element={<div>Сторінки не існує</div>} />

    //   {/* <Route path="/news" element={<News />} /> */}
    // </Routes>
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route path="/login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route element={<RequireAuth allowedRoles={['logist']} />}>
          <Route path="/trucks-table" element={<TrucksTable />} />
          <Route path="/trucks" element={<Trucks />} />
          <Route path="/my-trips" element={<MyTrips />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['client']} />}>
          <Route path="/client" element={<ClientPage />}/>
        </Route>
      </Route>


    </Routes>

  );
};


export default App;
