const serverUrl = 'https://630393-vds-atp16363.tech.gmhost.pp.ua:5001'; //'http://5.154.181.113:5000'; //'http://192.168.1.4:5000'; // https://630393-vds-atp16363.tech.gmhost.pp.ua:5001
const checkpointsList = [
  {
    id: 1,
    stage: "До виїзду",
    checkpoints: [
      { id: 1, name: "Рейс створено, водій ще не прийняв", color: "#FFF228" }, 
      { id: 2, name: "Водій прийняв рейс", color: "#37FF2C" }, 
    ],
  },
  {
    id: 2,
    stage: "Точка завантаження",
    checkpoints: [
      { id: 3, name: "Переїзд на завантаження", color: "#37FF2C" }, 
      { id: 4, name: "Завантаження", color: "#03FCCF" }, 
      { id: 5, name: "Завантажений, слідую за маршрутом", color: "#37FF2C" }, 
    ],
  },
  {
    id: 3,
    stage: "Точка розвантаження",
    checkpoints: [
      { id: 6, name: "Проміжне розвантаження", color: "#F290FF" }, 
      { id: 7, name: "Кінцеве розвантаження", color: "#F290FF" }, 
    ],
  },
  {
    id: 4,
    stage: "Опціонально",
    checkpoints: [
      { id: 8, name: "Відпочинок", color: "#ADFF58" }, 
      { id: 9, name: "Ремонт", color: "#FF1A00" }, 
      { id: 10, name: "Повернувся на маршрут", color: "#37FF2C" }, 
    ],
  },
  {
    id: 5,
    stage: "Кінець рейса",
    checkpoints: [{ id: 11, name: "Рейс завершено", color: "#E1E3E3" }], // Серый
  },
];


export { serverUrl, checkpointsList};
