function extractTemperature(temperatureString) {
    if (!temperatureString || typeof temperatureString !== 'string') {
        return null; // Повернути null для невірних параметрів
    }

    const match = temperatureString.match(/-?[\d.]+/);

    if (match) {
        const temperature = parseFloat(match[0]);
        if (!isNaN(temperature)) { // Перевірка на коректне число
            return temperature;
        }
    }
    return null; // Повернути null для некоректних даних
}

function getTemperatureColor(temperature) {
    if (temperature === null) {
        return 'black'; // Повернути колір за замовчуванням для некоректних даних
    } else {
        return 'blue';
    }

}


function formatAddress(address) {
    if (!address) {
        return 'Адреса відсутня';
    }


    // const road = address?.road || '';
    if (address.display_name) {
        return address.display_name;
    }
    const village = address?.village ? address.village + "\n" : '';
    const city = address?.city ? address.city + "\n" : '';
    const municipality = address?.municipality ? address.municipality + "\n" : '';
    const district = address?.district ? address.district + "\n" : '';
    const state = address?.state ? address.state + "\n" : '';
    const country = address?.country ? address.country + "\n" : '';

    return village + city + municipality + district + state + country;
}

export { getTemperatureColor, formatAddress, extractTemperature }