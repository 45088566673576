import React from 'react';
import { Typography, Tooltip, Box } from '@mui/material';
import { FaUserTie, FaTruck, FaUserPlus, FaBuilding } from 'react-icons/fa';

function Person({person, role}) {
  const RoleIcon = () => {
    switch (role) {
      case 'Логіст':
        return <FaUserTie />;
      case 'Водій':
        return <FaTruck />;
      case 'Клієнт':
        return <FaBuilding />;
      default:
        return <FaUserPlus />;
    }
  };

  return (
    <Tooltip title={role}>
      <Box display="flex" alignItems="center" gap={1}>
        <RoleIcon />
        <Typography variant="body2">
          {role === 'Клієнт' ? person.name : `${person?.last_name} ${person?.first_name}`}
        </Typography>
      </Box>
    </Tooltip>
  );
}

export default Person;
