import React, { } from "react";
import { calculateIdleTime } from "../utils/dateUtils";
import { FaRegCalendarTimes } from 'react-icons/fa';

const TruckInfo = ({ item }) => {

    if (item.routes.length === 0) {
        return (
            <div className="idle truck-info">
                <b>{item.truck.number}</b>

                <div style={{ fontSize: "10px" }}>{item.last_route.trailer.number}</div>
                {/* {item.truck.region_type !== 'Україна' &&
                    item.truck.region_type !== 'Міжнародне авто В' &&
                    item.truck.region_type !== 'Міжнародне авто I' ?
                    (<div style={{ color: 'blue' }}>{item.truck.region_type}</div>) :
                    (<div></div>)} */}
                <div>{" "}{item.last_route.driver.last_name}  {item.last_route.driver.first_name.slice(0, 1) + "."}</div>
                <div style={{color: 'green', fontStyle: 'italic'}}><b>
                    {item.last_route.intern && item.last_route.intern.first_name && item.last_route.intern.first_name != "вказано"? (
                        `${item.last_route.intern.last_name} ${item.last_route.intern.first_name.slice(0, 1)}.`
                    ) : (
                       <div></div>
                    )}
                </b></div>
                <div> <i> {
                    calculateIdleTime(item.last_route.checkpoints[item.last_route
                        .checkpoints.length - 1].date)}
                </i></div>
            </div>
        );
    } else if (item.routes.length > 0) {
        {
            if (item.truck.region_type !== 'Україна' &&
                item.truck.region_type !== 'Міжнародне авто В' &&
                item.truck.region_type !== 'Міжнародне авто I') {
            }
        }
        return (
            <div className="truck-info">
                <b>{item.truck.number}</b>
                <div style={{ fontSize: "10px" }}>{item.routes[0].trailer.number}</div>
                {/* {item.truck.region_type !== 'Україна' &&
                    item.truck.region_type !== 'Міжнародне авто В' &&
                    item.truck.region_type !== 'Міжнародне авто I' ?
                    (<div style={{ color: 'blue' }}>{item.truck.region_type}</div>) :
                    (<div></div>)} */}
                <div>
                    {" "}{item.routes[0].driver.last_name} {item.routes[0].driver.first_name.slice(0, 1) + "."}
                    {/* {" "}{item.routes[0].checkpoints[item.routes[0].checkpoints.length - 1].name !== 'Рейс завершено' && ((new Date(item.routes[0].unload_date)) < (new Date())) ? <b style={{ color: 'red', paddingTop: '20px' }}><FaRegCalendarTimes /></b> : <></>} */}
                </div>
                <div style={{color: 'green', fontStyle: 'italic'}}><b>
                    {item.routes[0].intern && item.routes[0].intern.first_name && item.routes[0].intern.first_name != "вказано"? (
                        `${item.routes[0].intern.last_name} ${item.routes[0].intern.first_name.slice(0, 1)}.`
                    ) : (
                       <div></div>
                    )}
                </b></div>


            </div>
        );
    } else {
        return <div></div>;
    }
};


export default TruckInfo;
