// MapWithRoute.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, Popup, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { icon } from 'leaflet';
import { Box, Typography } from '@mui/material';
import { formatDate } from '../utils/dateUtils';
import { fetchTrackPositionsForTrip, getLastGpsPositionVehicle } from '../api/GpsApi';
const MapWithRoute = ({ trip }) => {

  const [tripPoints, setTripPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastDate, setLastDate] = useState(null);
  const [temperature, setTemperature] = useState("");

  const customIcon = icon({
    iconUrl: '/icons/marker.svg',
    iconSize: [25, 41], // размеры иконки, например, [ширина, высота]
  });

  const checkpointIcon = icon({
    iconUrl: '/icons/chekpoint-marker.svg',
    iconSize: [25, 41], // размеры иконки, например, [ширина, высота]
  });

  const getRandomDirection = () => {
    const directions = ['top', 'bottom', 'left', 'right'];
    const randomDirection = directions[Math.floor(Math.random() * directions.length)];
    return randomDirection;
  };

  function transformCheckpointsToPoints(checkpoints) {
    const validCheckpoints = checkpoints.filter(checkpoint => checkpoint.vehicleGPS?.latitude && checkpoint.vehicleGPS?.longitude);

    const points = validCheckpoints.map(checkpoint => ({
      lat: checkpoint.vehicleGPS.latitude,
      lng: checkpoint.vehicleGPS.longitude,
      name: checkpoint.name,
      date: checkpoint.date,
      address: checkpoint.vehicleGPS.address,
      temperature: checkpoint.vehicleGPS.temperature
    }));

    return points;
  }


  useEffect(() => {
    let points;
    let data;

    if (trip.trailer.gpsId || trip.truck.gpsId) {
      const getPoints = async () => {

        if (trip.checkpoints[trip.checkpoints.length - 1].name === 'Рейс завершено') {
          const { track, date } = await fetchTrackPositionsForTrip(trip._id);
          points = track;
          setLastDate(date);
        } else if (trip.checkpoints[trip.checkpoints.length - 1].name === 'Поточна локація') {
          data = await getLastGpsPositionVehicle(trip.truck, trip.trailer);
          setLastDate(data.date)
          setTemperature(data.temperature);
          points = [[data.latitude, data.longitude]];
        }
        else {
          const { track, date } = await fetchTrackPositionsForTrip(trip._id);
          points = track;
          setLastDate(date);
        }

        let formatedPoints = points.map((point, index) => ({
          lat: point[0],
          lng: point[1],
          name: `Точка ${index + 1}`,
        }));
        return formatedPoints;
      };

      const fetchData = async () => {
        setLoading(true);
        const points = await getPoints();
        setTripPoints(points);
        setLoading(false);
      };

      fetchData();
    }
  }, [trip]);

  const defaultCenter = tripPoints[0];
  const defaultZoom = 13;

  if (trip.truck.gpsId == null && trip.trailer.gpsId == null) { return <h6>Трекінг не підключено</h6> }
  else if (loading === true) { return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}><Typography>Завантаження...</Typography></Box> }
  else {

    return (

      <MapContainer
        center={defaultCenter}
        zoom={defaultZoom}
        style={{ height: "100%", overflow: 'hidden', borderRadius: '5px' }}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          title={`Остання дата: ${formatDate(lastDate)}\n Остання температура: ${temperature}`}
          icon={customIcon}
          key={0}
          position={tripPoints[0]} >
        </Marker>
        <Polyline positions={tripPoints} color="blue" />
        {
          transformCheckpointsToPoints(trip.checkpoints).map((point, index) => (
            <Marker key={index} position={point} icon={checkpointIcon} >
              <Tooltip permanent direction={getRandomDirection()}>
                <Popup>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Box>
                      <Typography style={{ margin: 0 }}>
                        {formatDate(point.date)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color={'blue'} style={{ margin: 0 }}>
                        {point.temperature}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant='body2' style={{ margin: 0, paddingBottom: 20 }}>
                      {point.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' style={{ margin: 0 }}>
                      {point.address}
                    </Typography>
                  </Box>
                </Popup>
                <Typography variant='body2'>
                  {point.name}
                </Typography>
              </Tooltip>
            </Marker>
          ))
        }
      </MapContainer>
    );
  }
};

export default MapWithRoute;
