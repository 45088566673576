import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { fetchUsersPaginated, updateUser } from "../../api/UsersApi";
import ReactPaginate from "react-paginate";
import "../../styles/pagination.css";
import UsersTableRow from "./UsersTableRow";
import { FaPlusCircle } from "react-icons/fa";
import EditUserDialog from "./EditUserDialog";
const UsersTable = () => {

    const [users, setUsers] = useState([]);

    const [creationDialogOpen, setCreationDialogOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 15;

    const fetchUsersPage = async (page, limit) => {
        const fetchedUsers = await fetchUsersPaginated(page, limit);
        setUsers(fetchedUsers.users);
        setTotalPages(fetchedUsers.totalPages);
    }

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected + 1);

    }

    const toogleCreationDialog = () => {
        setCreationDialogOpen(!creationDialogOpen);
    }

    const handleSaveUser = (formData) => {
        updateUser(formData);
    };

    useEffect(() => {
        fetchUsersPage(page, limit);
    }, [page, limit]);

    if (!users) {
        return <Box height={'100vh'} pl={3} pt={10}>
            <Typography variant='h3'>Завантаження...</Typography>
        </Box>
    }

    return <Container disableGutters>
        <Grid container pb={1}>
            <Grid container item sm={3} direction="row" justifyContent={'center'} alignItems="center">
                <Grid item>
                    <Typography variant="h6" textAlign="center">ПІБ</Typography>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" pl={1}>
                        <Button title="Створити користувача" onClick={toogleCreationDialog} >
                            <FaPlusCircle size={20} color="#e996a6" />

                        </Button>
                        <EditUserDialog
                            open={creationDialogOpen}
                            onSave={handleSaveUser}
                            onClose={toogleCreationDialog}
                            user={{
                                role: 'client',
                                isDeleted: false,
                                clients: [],
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>



            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Роль</Typography>
            </Grid>
            <Grid item sm={2} >
                <Typography variant="h6" textAlign={'center'}>Телефон</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Пароль</Typography>
            </Grid>
            <Grid item sm={3} >
                <Typography variant="h6" textAlign={'center'}>Група клієнтів</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Посвідчення</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Дії</Typography>
            </Grid>
        </Grid>
        <Grid container overflow={'auto'} maxHeight={450}>
            {users.map((user, index) => (
                <UsersTableRow user={user} key={user._id} />

            ))}
        </Grid>
        <Box mt={3} pb={3}>
            <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel="Попередня"
                nextLabel="Наступна"
            />
        </Box>
    </Container>

};

export default UsersTable;