import moment from 'moment';

const formatDate = (dateString) => {
    let date = new Date(dateString);
    let today = new Date();
    let yesterday = new Date(today.setDate(today.getDate() - 1));
    let formattedDate;
  
    let localeDate =  moment(date).format('HH:mm')
    if (moment(date).format('DD.MM.YY') === ( moment().format('DD.MM.YY'))) {
          formattedDate = 'сьогодні, ' + localeDate;
    } else if (moment(date).format('DD.MM.YY') === ( moment(yesterday).format('DD.MM.YY'))) {
      formattedDate = 'вчора, ' +  localeDate;
    } else {
      formattedDate = moment(date).format('DD.MM.YY, HH:mm')
    }
    return formattedDate;
  };

  const formatDateFull = (dateString) => {
    return moment(dateString).format('DD.MM.YY, HH:mm')
  };

  const formatDateWithoutYear = (dateString) => {
    return moment(dateString).format('DD.MM, HH:mm')
  };

  const formatDateForTeletrackApi = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD HH:mm')
  };
  
  const calculateIdleTime = (startTime) => {
    const now = new Date();
    const startDate = new Date(startTime);
    const diff = now.getTime() - startDate.getTime();
  
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  
    return `${days} д. ${hours} г.`;
  }

    const formatIdleTime = (downtime) => {
  
    const days = Math.floor(downtime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((downtime / (1000 * 60 * 60)) % 24);
  
    return `${days} д. ${hours} г.`;
  }

  const calculateIdleTimeForCurrentMonth = (routes) => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  let idleTime = 0;
    let routesCal = [{unload_date: firstDayOfMonth}, ...routes, {load_date: now}];
  for (let i = 0; i < routesCal.length - 1; i++) {
    const currentTrip = routesCal[i];
    const nextTrip = routesCal[i + 1];

    const currentTripMonth = new Date(currentTrip.unload_date).getMonth();
    const nextTripMonth = new Date(nextTrip.load_date).getMonth();

    if (currentTripMonth === currentMonth && nextTripMonth === currentMonth) {
      const currentTripUnloadTime = new Date(currentTrip.unload_date).getTime();
      const nextTripLoadTime = new Date(nextTrip.load_date).getTime();

      if (currentTripUnloadTime < now.getTime()) {
        idleTime += nextTripLoadTime - currentTripUnloadTime;
      }
    } else if (nextTripMonth === currentMonth){
      const currentTripUnloadTime = firstDayOfMonth.getTime();
      const nextTripLoadTime = new Date(nextTrip.load_date).getTime();

      if (currentTripUnloadTime < now.getTime()) {
        idleTime += nextTripLoadTime - currentTripUnloadTime;

      }
    }
  }
  const days = Math.floor(idleTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((idleTime / (1000 * 60 * 60)) % 24);
  
  
    return `${days} днів ${hours} годин`;
  }

  function calculateDistanceCurrentMonth(routes) {
    
    // Получаем текущую дату и первый день текущего месяца
    const currentDate = new Date();
    const currentMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    // Отфильтровываем все рейсы за текущий месяц, которые содержат чекпоинт с именем "Водій завершив рейс"
    const routesCurrentMonth = routes.filter(route => {
      const lastCheckpoint = route.checkpoints[route.checkpoints.length - 1];
      return lastCheckpoint.name === 'Рейс завершено'
        && new Date(route.load_date) >= currentMonthFirstDay
        // && new Date(route.load_date) <= currentDate;
    });

    // Для каждого рейса вычисляем дистанцию и суммируем их
    const distanceCurrentMonth = routesCurrentMonth.reduce((acc, route) => {
      return acc + route.distance;
    }, 0);

    return distanceCurrentMonth;
  }
  
  export { formatDate, 
    formatDateFull, 
    calculateIdleTime, 
    calculateIdleTimeForCurrentMonth, 
    calculateDistanceCurrentMonth, 
    formatDateForTeletrackApi,
    formatDateWithoutYear,
    formatIdleTime
  };