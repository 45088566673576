import React, { useState } from "react";
import { Grid, Typography, Button, ButtonGroup, Chip, Box, IconButton } from '@mui/material';
import "../../styles/ClientTripsTable.css";
import { formatDate, formatDateFull } from "../../utils/dateUtils";
import { FaInfoCircle, FaMapMarkerAlt, FaTrailer, FaTruck } from "react-icons/fa";
import { findCheckpointColor } from "../../utils/checkpointsUtils";
import ClientTripDetailsDialog from "./ClientTripDetailsDialog";
import { useEffect } from "react";
import { fetchLastGpsPositionForTrip, fetchLocationData } from "../../api/GpsApi";

const ClientTripsTableRow = ({ trip }) => {

    const [openTripDialog, setOpenTripsDialog] = useState(false);
    const lastCheckpoint = trip.checkpoints[trip.checkpoints.length - 1];
    const [currentGPS, setCurrentGPS] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const handleOpenTripDialog = () => {
        setOpenTripsDialog(true);
    }

    const handleCloseTripDialog = () => {
        setOpenTripsDialog(false);
    }

    useEffect(() => {
        async function fetchCurrentGPS(trip_id) {
            let location;
            if (lastCheckpoint.name === 'Рейс завершено') {
                setCurrentGPS(lastCheckpoint.vehicleGPS);
            } else {
                location = await fetchLastGpsPositionForTrip(trip_id);
                let address = await fetchLocationData(location.latitude, location.longitude);
                setCurrentGPS({ date: location.date, address: address.display_name });
            }
            setIsLoading(false); // Перемісти це сюди, після завершення асинхронних операцій
        }
        fetchCurrentGPS(trip._id);
    }, [trip._id, lastCheckpoint.name, lastCheckpoint.vehicleGPS]);

    return (
        <Grid
            container
            p={1}
            className={'clientTripsTableRow'}

        >

            <Grid item container direction={'column'} sm={1} xs={12} textAlign={'center'}>
                <Grid item>
                    <Typography variant="caption" fontFamily={'Roboto'}>
                        {formatDateFull(trip.load_date)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" fontFamily={'Roboto'}>
                        {formatDateFull(trip.unload_date)}
                    </Typography>
                </Grid>


            </Grid>
            <Grid container item sm={5} xs={12} pl={1} className={'clientTripsTableRowAddress'}>
                <Grid item sm={6}>
                    <Typography variant="caption" fontFamily={'Roboto'} >
                        {(trip.point_load.country === 'УКРАЇНА' ? "" : trip.point_load.country) + " "
                            + trip.point_load.region + " "
                            + trip.point_load.city + " "
                            + trip.point_load.street + " "
                            + trip.point_load.building
                        }
                    </Typography>
                </Grid>
                <Grid item sm={6} pl={1}>
                    <Typography variant="caption" fontFamily={'Roboto'}>
                        {(trip.point_unload.country === 'УКРАЇНА' ? "" : trip.point_load.country) + " "
                            + trip.point_unload.region + " "
                            + trip.point_unload.city + " "
                            + trip.point_unload.street + " "
                            + trip.point_unload.building
                        }
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container sm={1} xs={12} textAlign={'left'} direction={'column'} pl={1}>
                <Grid item>
                    <Typography fontSize={12} fontFamily={'Roboto'}>
                        {trip.driver.last_name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography fontSize={12} fontFamily={'Roboto'}>
                        {trip.driver.first_name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography fontSize={12} fontFamily={'Roboto'}>
                        {trip.driver.surname}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container sm={1} xs={12} textAlign={'center'} direction={'column'} >
                <Grid>
                    <FaTruck size={10} />
                    <Typography variant="caption" fontFamily={'Roboto'}>
                        {trip.truck.number}
                    </Typography>
                </Grid>
                <Grid>
                    <FaTrailer size={10} />
                    <Typography variant="caption" fontFamily={'Roboto'}>
                        {trip.trailer.number}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item sm={2} xs={12}>
                {isLoading ? (<Typography>Завантаження</Typography>) : (
                    <>{
                        currentGPS ? (
                            <Grid container item style={{ fontSize: '11px', maxHeight: '70px', overflow: 'hidden' }}
                                title={currentGPS ? formatDate(currentGPS.date) + "\n"
                                    + currentGPS.address.replaceAll(',', '\n') : ''}>
                                <Grid item>
                                    <Typography
                                        fontSize={10}
                                        fontFamily={'Roboto'}
                                        variant="caption"
                                        color="grey"
                                    >
                                        {currentGPS ? formatDate(currentGPS.date) : ''}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontSize={10} fontFamily={'Roboto'} style={{ lineHeight: 0.9 }}>
                                        {currentGPS ? currentGPS.address : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                            </>
                        )
                    }</>
                )}
            </Grid>


            <Grid item container sm={2} xs={12} justifyContent={'center'}>
                <Grid item sm={10}>
                    <Box
                        style={{
                            cursor: 'default',
                            height: '100%',
                            backgroundColor: findCheckpointColor(lastCheckpoint.name),
                            borderRadius: '10px',
                            padding: '1px',
                            display: 'flex',
                            alignItems: 'center', // Вирівнювання по вертикалі
                            justifyContent: 'center', // Вирівнювання по горизонталі
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="caption" fontFamily={'Roboto'} col={findCheckpointColor(lastCheckpoint.name)}>
                            {lastCheckpoint.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={2} alignSelf={'center'} textAlign={'center'} >
                    <IconButton title="Отримати розширену інформацію" onClick={handleOpenTripDialog}>
                        <FaInfoCircle size={20} />
                    </IconButton>
                    <ClientTripDetailsDialog
                        open={openTripDialog}
                        onClose={handleCloseTripDialog}
                        trip={trip}
                    />
                </Grid>

            </Grid>


        </Grid >
    );
};

export default ClientTripsTableRow;
