import React, { useState, useEffect } from "react";
import { fetchDrivers } from "../api/UsersApi";
import { Box, List, ListItem, ListItemText, Typography, TextField } from "@mui/material";

function Drivers() {
    const [drivers, setDrivers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchDriversData = async () => {
            const users = await fetchDrivers();
            setDrivers(users);
        }
        setIsLoading(true);
        fetchDriversData();
        setIsLoading(false);
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredDrivers = drivers.filter(driver =>
        driver.last_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isLoading) {
        return <Typography>Завантаження...</Typography>;
    }

    return (
        <Box pt={6}>
            <Typography variant="h3">Список водіїв</Typography>
            <TextField
                label="Пошук за прізвищем"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                margin="normal"
            />
            <List>
                {
                    filteredDrivers.sort((a, b) => a.last_name.localeCompare(b.last_name))
                        .map((driver, index) => (
                            <ListItem key={index}>
                                <ListItemText style={{ maxWidth: '200px' }}>
                                    {driver.last_name} {driver.first_name}
                                </ListItemText>
                                <ListItemText style={{ maxWidth: '200px' }}>
                                    {driver.code}
                                </ListItemText>
                                {driver.currentToken ?
                                    (<ListItemText style={{ color: 'blue', maxWidth: '200px' }}>
                                        В застосунку</ListItemText>) :
                                    (<ListItemText style={{ color: 'red', maxWidth: '200px' }}>
                                        Не встановлено
                                    </ListItemText>)
                                }
                            </ListItem>
                        ))
                }
            </List>
        </Box>
    );
}

export default Drivers;
