import { createContext, useState, useContext, useEffect } from 'react';
import {serverUrl} from './config';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userObj = JSON.parse(localStorage.getItem("user"));
    if (token) {
      setToken({ token });
      setUser( userObj );
    }
    setIsLoading(false);
  }, []);

  const login = async (code) => {
    try {
      const response = await fetch(`${serverUrl}/api/loginWeb/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: code }),
      });
  
      if (!response.ok) {
        throw new Error('Помилка сервера');
      }
  
      const data = await response.json();
  
      if (data.currentTokenWeb) {
        setToken(data.currentTokenWeb);
        setUser(data);
        localStorage.setItem('token', data.currentTokenWeb);
        localStorage.setItem('user', JSON.stringify(data));
      } else {
        throw new Error(data.err);
      }
    } catch (err) {
      throw new Error('Помилка авторізації, можливо пароль невірний');
    }
  };
  

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };
  return (
    <AuthContext.Provider value={{ user, token, login, logout, isLoading  }}>
      {children}
    </AuthContext.Provider>
  );
};
