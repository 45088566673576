
import React, { useState, useEffect } from "react";
import { Container, List, ListItem, ListSubheader, Grid, Typography } from '@mui/material';
import { fetchTrucks } from "../api/TrucksApi";
import "../styles/TrucksTable.css"
import "../styles/App.css"
import TruckRow from "./TruckRow";

const TrucksTable = () => {
    const [trucks, setTrucks] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const fetchTrucksData = async () => {
        const fetchedTrucks = await fetchTrucks();
        setTrucks(fetchedTrucks);
    };

    useEffect(() => {
        if (!isEditing) {
            fetchTrucksData();
            const intervalId = setInterval(() => {
                fetchTrucksData();
            }, 180000);

            // Очистка интервала при размонтировании
            return () => clearInterval(intervalId);
        }
    }, [isEditing]);

    useEffect(() => {
        // Обновляем значение мета-тега viewport при монтировании компонента
        const metaViewport = document.querySelector('meta[name="viewport"]');
        metaViewport.setAttribute('content', 'width=1200, initial-scale=1');

        // Очищаем значение мета-тега viewport при размонтировании компонента
        return () => {
            metaViewport.setAttribute('content', 'width=device-width, initial-scale=1');
        };
    }, []);

    return (
        <Container maxWidth="false" disableGutters sx={{ paddingTop: 6 }}>
            <Grid
                container
                paddingLeft={1}
                paddingRight={1}
                position={'fixed'}
                zIndex={400}
                className="table-header"
            >
                <Grid item sm={1} textAlign={'center'}>Авто</Grid>
                <Grid item sm={2} textAlign={'center'}>Останній рейс</Grid>
                <Grid item sm={2} textAlign={'center'}>Поточний рейс</Grid>
                <Grid item sm={2} textAlign={'center'}>Майбутній рейс</Grid>
                <Grid item sm={1} textAlign={'center'}>GPS</Grid>
                <Grid item sm={2} textAlign={'center'}>Логістика</Grid>
                <Grid item sm={1} textAlign={'center'}>Тех. служба</Grid>
                <Grid item sm={1} textAlign={'center'}>Інша інфо.</Grid>
            </Grid>
            <Grid container style={{ border: '1px solid black', paddingTop: '10px' }}>
                <Grid item xs={12}>
                    <List>
                        <ListSubheader className="category" disableSticky ><Typography variant="h4" >Міжнародні авто</Typography></ListSubheader>

                        {trucks.filter((item) =>
                            item.truck.region_type === 'Міжнародне авто В' ||
                            item.truck.region_type === 'Міжнародне авто I')
                            .map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <Grid item xs={12} className="table-row">
                                        <TruckRow key={item.truck._id}
                                            item={item}
                                            isEditing={isEditing}
                                            setIsEditing={setIsEditing} />
                                    </Grid>
                                </ListItem>
                            ))}
                        <ListSubheader className="category" disableSticky ><Typography variant="h4" >Україна</Typography></ListSubheader>
                        {trucks.filter((item) =>
                            item.truck.region_type === 'Україна')
                            .map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <Grid item xs={12} className="table-row">
                                        <TruckRow
                                            key={item.truck._id}
                                            item={item}
                                            isEditing={isEditing}
                                            setIsEditing={setIsEditing} />
                                    </Grid>
                                </ListItem>
                            ))}
                        <ListSubheader className="category" disableSticky >
                            <Typography variant="h4" >
                                Перевізники
                            </Typography>
                        </ListSubheader>

                        {/* {trucks
                            .filter((item) =>
                                item.truck.region_type !== 'Україна' &&
                                item.truck.region_type !== 'Міжнародне авто В' &&
                                item.truck.region_type !== 'Міжнародне авто I'
                            )
                            .filter((item) => {
                                const lastCheckpoint = item.last_route.checkpoints[item.last_route.checkpoints.length - 1];
                                if (
                                    (lastCheckpoint.name === 'Рейс завершено' &&
                                        Date.now() - new Date(lastCheckpoint.date).getTime() > 60 * 60 * 1000)
                                    && (!item.truck.region_type || item.truck.region_type === '')
                                ) {
                                    return false; // Виключаємо авто з "Рейс завершено" та старіше години
                                }
                                return true; // Залишаємо решту авто
                            })
                            .map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <Grid item xs={12} className="table-row">
                                        <TruckRow
                                            key={item.truck._id}
                                            item={item}
                                            isEditing={isEditing}
                                            setIsEditing={setIsEditing}
                                        />
                                    </Grid>
                                </ListItem>
                            ))} */}
                        {Object.entries(
                            trucks
                                .filter((item) =>
                                    item.truck.region_type !== 'Україна' &&
                                    item.truck.region_type !== 'Міжнародне авто В' &&
                                    item.truck.region_type !== 'Міжнародне авто I'
                                )
                                .filter((item) => {
                                    const lastCheckpoint = item.last_route.checkpoints[item.last_route.checkpoints.length - 1];
                                    if (
                                        (lastCheckpoint.name === 'Рейс завершено' &&
                                            Date.now() - new Date(lastCheckpoint.date).getTime() > 60 * 60 * 1000)
                                        && (!item.truck.region_type || item.truck.region_type === '')
                                    ) {
                                        return false; // Исключаем авто с "Рейс завершено" и старше часа
                                    }
                                    return true; // Оставляем остальные авто
                                })
                                .reduce((acc, item) => {
                                    const regionType = item.truck.region_type || 'Інші';
                                    if (!acc[regionType]) {
                                        acc[regionType] = [];
                                    }
                                    acc[regionType].push(item);
                                    return acc;
                                }, {})
                        )
                            .sort(([a], [b]) => {
                                if (a === 'Інші') return 1;
                                if (b === 'Інші') return -1;
                                return 0;
                            })
                            .map(([regionType, items]) => (
                                <React.Fragment key={regionType}>
                                    <ListSubheader className="category" disableSticky>
                                        <Typography variant="h5" color={'blue'}>{regionType}</Typography>
                                    </ListSubheader>
                                    {items.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <Grid item xs={12} className="table-row">
                                                <TruckRow
                                                    key={item.truck._id}
                                                    item={item}
                                                    isEditing={isEditing}
                                                    setIsEditing={setIsEditing}
                                                />
                                            </Grid>
                                        </ListItem>
                                    ))}
                                </React.Fragment>
                            ))}




                    </List>
                </Grid>
            </Grid>
        </Container>
    );
};

export default TrucksTable;


// Рефактор от чата GPT:
// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   List,
//   ListItem,
//   ListSubheader,
//   Grid,
//   Typography,
// } from "@mui/material";
// import { fetchTrucks } from "../api/TrucksApi";
// import "../styles/TrucksTable.css";
// import "../styles/App.css";
// import TruckRow from "./TruckRow";

// const TrucksTable = () => {
//   const [trucks, setTrucks] = useState([]);
//   const [isEditing, setIsEditing] = useState(false);

//   const INTERNATIONAL_TRUCKS = ["Міжнародне авто В", "Міжнародне авто I"];
//   const UKRAINE_TRUCKS = ["Україна"];
//   const OTHER = "Інші";

//   useEffect(() => {
//     const fetchTrucksData = async () => {
//       const fetchedTrucks = await fetchTrucks();
//       setTrucks(fetchedTrucks);
//     };

//     if (!isEditing) {
//       fetchTrucksData();
//       const intervalId = setInterval(fetchTrucksData, 180000);

//       return () => clearInterval(intervalId);
//     }
//   }, [isEditing]);

//   useEffect(() => {
//     const metaViewport = document.querySelector('meta[name="viewport"]');
//     metaViewport.setAttribute("content", "width=1200, initial-scale=1");

//     return () => {
//       metaViewport.setAttribute("content", "width=device-width, initial-scale=1");
//     };
//   }, []);

//   const filterTrucksByRegion = (regionTypes) =>
//     trucks.filter((item) => regionTypes.includes(item.truck.region_type));

//   const getCarrierTrucks = () => {
//     const carriers = trucks.filter(
//       (item) =>
//         !INTERNATIONAL_TRUCKS.includes(item.truck.region_type) &&
//         !UKRAINE_TRUCKS.includes(item.truck.region_type)
//     );

//     return carriers.filter((item) => {
//       const lastCheckpoint =
//         item.last_route.checkpoints[item.last_route.checkpoints.length - 1];
//       const isFinishedRoute =
//         lastCheckpoint.name === "Рейс завершено" &&
//         Date.now() - new Date(lastCheckpoint.date).getTime() > 60 * 60 * 1000;
//       const hasNoRegionType = !item.truck.region_type || item.truck.region_type === "";

//       return !(isFinishedRoute && hasNoRegionType);
//     });
//   };

//   const groupTrucksByRegionType = (trucksList) =>
//     trucksList.reduce((acc, item) => {
//       const regionType = item.truck.region_type || OTHER;
//       acc[regionType] = acc[regionType] || [];
//       acc[regionType].push(item);
//       return acc;
//     }, {});

//   const renderTruckList = (title, trucksList) => (
//     <>
//       <ListSubheader className="category" disableSticky>
//         <Typography variant="h4">{title}</Typography>
//       </ListSubheader>
//       {trucksList.map((item) => (
//         <ListItem key={item.truck._id} disablePadding>
//           <Grid item xs={12} className="table-row">
//             <TruckRow
//               key={item.truck._id}
//               item={item}
//               isEditing={isEditing}
//               setIsEditing={setIsEditing}
//             />
//           </Grid>
//         </ListItem>
//       ))}
//     </>
//   );

//   const internationalTrucks = filterTrucksByRegion(INTERNATIONAL_TRUCKS);
//   const ukraineTrucks = filterTrucksByRegion(UKRAINE_TRUCKS);
//   const carrierTrucks = getCarrierTrucks();
//   const groupedCarrierTrucks = groupTrucksByRegionType(carrierTrucks);
//   const sortedRegionTypes = Object.keys(groupedCarrierTrucks).sort((a, b) => {
//     if (a === OTHER) return 1;
//     if (b === OTHER) return -1;
//     return 0;
//   });

//   return (
//     <Container maxWidth={false} disableGutters sx={{ paddingTop: 6 }}>
//       <Grid
//         container
//         paddingLeft={1}
//         paddingRight={1}
//         position="fixed"
//         zIndex={400}
//         className="table-header"
//       >
//         <Grid item sm={1} textAlign="center">
//           Авто
//         </Grid>
//         <Grid item sm={2} textAlign="center">
//           Останній рейс
//         </Grid>
//         <Grid item sm={2} textAlign="center">
//           Поточний рейс
//         </Grid>
//         <Grid item sm={2} textAlign="center">
//           Майбутній рейс
//         </Grid>
//         <Grid item sm={1} textAlign="center">
//           GPS
//         </Grid>
//         <Grid item sm={2} textAlign="center">
//           Логістика
//         </Grid>
//         <Grid item sm={1} textAlign="center">
//           Тех. служба
//         </Grid>
//         <Grid item sm={1} textAlign="center">
//           Інша інфо.
//         </Grid>
//       </Grid>
//       <Grid container style={{ border: "1px solid black", paddingTop: "10px" }}>
//         <Grid item xs={12}>
//           <List>
//             {renderTruckList("Міжнародні авто", internationalTrucks)}
//             {renderTruckList("Україна", ukraineTrucks)}
//             <ListSubheader className="category" disableSticky>
//               <Typography variant="h4">Перевізники</Typography>
//             </ListSubheader>
//             {sortedRegionTypes.map((regionType) => (
//               <React.Fragment key={regionType}>
//                 <ListSubheader className="category" disableSticky>
//                   <Typography variant="h5" color="blue">
//                     {regionType}
//                   </Typography>
//                 </ListSubheader>
//                 {groupedCarrierTrucks[regionType].map((item) => (
//                   <ListItem key={item.truck._id} disablePadding>
//                     <Grid item xs={12} className="table-row">
//                       <TruckRow
//                         key={item.truck._id}
//                         item={item}
//                         isEditing={isEditing}
//                         setIsEditing={setIsEditing}
//                       />
//                     </Grid>
//                   </ListItem>
//                 ))}
//               </React.Fragment>
//             ))}
//           </List>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default TrucksTable;
