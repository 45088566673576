import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { formatDateWithoutYear } from '../utils/dateUtils';
import { FaRegCalendarTimes, FaSync } from 'react-icons/fa';
import { findCheckpointColor } from "../utils/checkpointsUtils";
import "../styles/TripDetails.css";
import TripDetails from './TripDetails';
import { AddCheckpointDialog } from './AddCheckpoint';
import { updateCheckpoint } from '../api/TripsApi';

function TripTooltip({ trip, onTripUpdate }) {
  const [checkpDialogOpen, setCheckpDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(trip);
  const [hasAttemptedUpdate, setHasAttemptedUpdate] = useState(false);

  const toggleDialog = (event) => {
    event.stopPropagation();
    setCheckpDialogOpen(!checkpDialogOpen);
  };

  const handleUpdateCheckpoint = async (event) => {
    event.stopPropagation();
    const checkpointIndex = currentTrip.checkpoints.length - 1;
    try {
      setIsUpdating(true);
      // Call the API to update the checkpoint
      const updatedTrip = await updateCheckpoint(currentTrip._id, checkpointIndex);
      setCurrentTrip(updatedTrip);
      setHasAttemptedUpdate(true); // Set flag to true after update attempt
      if (onTripUpdate) {
        onTripUpdate(updatedTrip);
      }
    } catch (error) {
      console.error('Error updating checkpoint:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Ensure that currentTrip and currentTrip.checkpoints are defined
  const hasCheckpoints = currentTrip?.checkpoints && currentTrip.checkpoints.length > 0;
  const lastCheckpoint = hasCheckpoints ? currentTrip.checkpoints[currentTrip.checkpoints.length - 1] : null;

  // Determine whether to show the FaSync icon and its color
  const showFaSyncIcon = lastCheckpoint?.vehicleGPS?.incomplete_data !== undefined;

  // Icon color logic
  let faSyncColor = 'inherit'; // Default color
  let faSyncTitle = 'Обновить дані чекпоінта';

  if (hasAttemptedUpdate && lastCheckpoint?.vehicleGPS?.incomplete_data === true) {
    // If update was attempted and incomplete_data is still true, show red icon
    faSyncColor = 'red';
    faSyncTitle = 'Не усі дані надійшли';
  }

  return (
    <Box className='info-block'>
      {currentTrip._id && (
        <Box
          key={currentTrip._id}
          style={{
            height: '100%',
            backgroundColor: findCheckpointColor(lastCheckpoint?.name || ''),
          }}
        >
          <Tooltip
            placement="right-start"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  border: "solid grey 1px",
                  backgroundColor: "#F2F5FD",
                  color: 'black',
                  maxWidth: "600px",
                },
              },
              disablePortal: true,
              popperOptions: {
                positionFixed: true,
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "window",
                  },
                },
              },
            }}
            title={
              <TripDetails
                selectedTrip={currentTrip}
                selectedTruck={currentTrip.truck}
                showCheckpoints={true}
              />
            }
          >
            <div className='status'>
              <Typography
                variant='caption'
                style={{ fontWeight: '500' }}
                onClick={toggleDialog}
              >
                {lastCheckpoint?.name || 'Немає чекпоінтів'}
              </Typography>
              <div
                style={{
                  paddingRight: '5px',
                  paddingTop: '3px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {lastCheckpoint?.name !== 'Рейс завершено' &&
                new Date(currentTrip.unload_date) < new Date() ? (
                  <FaRegCalendarTimes size={14} />
                ) : null}
                {showFaSyncIcon && (
                  <FaSync
                    size={14}
                    onClick={handleUpdateCheckpoint}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '5px',
                      color: faSyncColor,
                    }}
                    title={faSyncTitle}
                  />
                )}
              </div>
            </div>
          </Tooltip>
          <AddCheckpointDialog
            onAddCheckpoint={() => {}}
            open={checkpDialogOpen}
            trip={currentTrip}
            toggleDialog={toggleDialog}
          />
          <div className='trip-card-point'>
            <Typography fontFamily={'Roboto'} fontSize={12}>
              {currentTrip.point_load.city ? currentTrip.point_load.city : currentTrip.point_load.region}
            </Typography>
            <small>{formatDateWithoutYear(currentTrip.load_date)}</small>
          </div>
          <div className='trip-card-point'>
            <Typography fontFamily={'Roboto'} fontSize={12}>
              {currentTrip.point_unload.city ? currentTrip.point_unload.city : currentTrip.point_unload.region}
            </Typography>
            <small>{formatDateWithoutYear(currentTrip.unload_date)}</small>
          </div>
        </Box>
      )}
    </Box>
  );
}

export default TripTooltip;
