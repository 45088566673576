import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthContext";

const RequireAuth = ({ allowedRoles }) => {
    const { user } = useAuth();
    const location = useLocation();

    // Перевіряємо, чи користувач має права, допустимі для доступу
    const hasPermission = user && user.role && allowedRoles.some(role => user.role === role);

    return (
        hasPermission
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;