import React from "react";
import { calculateDistanceCurrentMonth, formatDateWithoutYear, formatIdleTime } from "../utils/dateUtils";
import { calculateDowntimeCheckpoints } from '../utils/downtime';
import "../styles/TruckList.css"
import { Box, Grid, Typography, Divider, Button, Chip } from '@mui/material';
import { FaTruck, FaArrowCircleDown, FaArrowCircleUp, FaTrailer, FaRuler, FaTags, FaMoneyCheck, FaComment } from 'react-icons/fa';
import { findCheckpointColor } from "../utils/checkpointsUtils";
import Person from "./Person";
import CheckpointsList from "./CheckpointList";

function TripDetails({ selectedTrip, selectedTruck, showCheckpoints, showIdle, isShowGPS }) {
    return (
        <Grid container direction={'row'}>
            <Grid item container sm={showCheckpoints ? 6 : 12} justifyContent={'center'} alignSelf={'flex-start'}>
                <Typography
                    paddingTop={1}
                    textAlign={'center'}
                    variant="body2"
                    color="text.secondary">
                    <Button
                        disableElevation
                        sx={{
                            backgroundColor: findCheckpointColor(selectedTrip
                                .checkpoints[selectedTrip.checkpoints.length - 1].name),
                            padding: '0px 5px 0px 5px',
                            borderRadius: '5px',
                            textTransform: 'none',
                            color: 'black'
                        }}
                    >
                        <Typography fontSize="12px" fontFamily={'Roboto'}>
                            {selectedTrip.checkpoints[selectedTrip.checkpoints.length - 1].name}
                        </Typography>
                    </Button>
                </Typography>
                <Grid container item style={{ marginTop: '15px' }}>
                    <Grid item xs={2} paddingLeft={'5px'} >
                        <Typography variant="body2" component="div" lineHeight={1}>
                            <small>{formatDateWithoutYear(selectedTrip.load_date)}</small>
                        </Typography>

                    </Grid>
                    <Grid item xs={10} >
                        <Typography variant="body2" color="text.primary" lineHeight={1} pb={1}>
                            <FaArrowCircleDown style={{ marginRight: '4px', color: '#ea96a5' }} />
                            Завантаження
                        </Typography>
                        <Typography
                            fontFamily={'Roboto'}
                            variant="body2"
                            color="text.secondary"
                            paddingRight={1}
                            lineHeight={1}
                        >
                            {`${selectedTrip.point_load.city}, 
                        ${selectedTrip.point_load.street} ${selectedTrip.point_load.building},
                         ${selectedTrip.point_load.region}, ${selectedTrip.point_load.country}`}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item style={{ marginTop: '15px' }}>
                    <Grid item xs={2} paddingLeft={'5px'}>
                        <Typography variant="body2" component="div" lineHeight={1}>
                            <small>{formatDateWithoutYear(selectedTrip.unload_date)}</small>
                        </Typography>

                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2" color="text.primary" lineHeight={1} pb={1}>
                            <FaArrowCircleUp style={{ marginRight: '4px', color: '#ea96a5' }} />
                            Розвантаження
                        </Typography>
                        <Typography
                            fontFamily={'Roboto'}
                            variant="body2"
                            color="text.secondary"
                            paddingRight={1}
                            lineHeight={1}>
                            {`${selectedTrip.point_unload.city}, 
                        ${selectedTrip.point_unload.street} 
                        ${selectedTrip.point_unload.building}, 
                        ${selectedTrip.point_unload.region}, 
                        ${selectedTrip.point_unload.country}`}
                        </Typography>
                    </Grid>
                </Grid>
                {selectedTrip.comment !== "" &&
                    <Typography
                        paddingTop={'10px'}
                        fontFamily={'Roboto'}
                        textAlign={'center'}
                        variant="body2"
                        color={"text.primary"}
                    >
                        <b><FaComment /> {selectedTrip.comment}</b>
                    </Typography>}

                <Grid container item direction={'row'} paddingTop={'10px'} justifyContent={
                    'space-evenly'
                }>

                    <Grid item>
                        <Typography variant="body2">
                            <FaRuler /> {selectedTrip.distance} км
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            <FaRuler
                                color={selectedTrip.checkpoints[selectedTrip.checkpoints.length - 1].vehicleGPS?.incomplete_data ? "red" : "green"}
                                title="По даним GPS"
                            />
                            {selectedTrip.checkpoints[selectedTrip.checkpoints.length - 1].vehicleGPS?.mileage} км
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="body2">
                            <FaTags /> {selectedTrip.price} ₴
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            <FaMoneyCheck /> {Math.round(selectedTrip.price /
                                selectedTrip.distance)} ₴/км
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item justifyContent={'space-evenly'} paddingTop={1}>
                    <Grid item alignSelf={'center'}>
                        <Person person={selectedTrip.client} role={'Клієнт'} />
                    </Grid>
                    <Grid container justifyContent={'space-evenly'} paddingBottom={1} >
                        <Grid item>
                            <Person person={selectedTrip.logist} role={'Логіст'} />
                        </Grid>
                        <Grid item>
                            <Person person={selectedTrip.next_logist} role={'Наст. логіст'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider>
                    <Chip color="primary" label="Дані про водія та авто" />
                </Divider>
                <Grid container item justifyContent={'space-evenly'} paddingTop={'5px'}>
                    <Grid item ><Person person={selectedTrip.driver} role={'Водій'} /></Grid>
                </Grid>
                {selectedTrip.intern && selectedTrip.intern.last_name &&
                    <Grid container item justifyContent={'space-evenly'} paddingTop={'5px'}>
                        <Grid item style={{ color: 'green' }} ><Person person={selectedTrip.intern} role={'Cтажер'} /></Grid>
                    </Grid>}

                <Grid container item justifyContent="space-evenly">

                    <Grid item>
                        <Typography variant="caption">
                            <FaTruck /> {selectedTrip.truck.number}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            <FaTrailer /> {selectedTrip.trailer.number}
                        </Typography>
                    </Grid>



                </Grid>
                <Grid container item justifyContent="space-evenly">

                    <Grid item>
                        <Typography pl={1} color={'grey'} variant="caption" fontStyle={'italic'}>
                            {selectedTrip.truck.gpsProvider}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography pl={1} color={'grey'} variant="caption" fontStyle={'italic'}>
                            {selectedTrip.trailer.gpsProvider}
                        </Typography>
                    </Grid>
                </Grid>

                {showIdle && <Grid item container justifyContent={'center'}>
                    <Grid item paddingLeft={"10px"}>
                        <Typography variant="body2" >
                            Пробіг (міс.): {calculateDistanceCurrentMonth(selectedTruck.routes)} км.
                        </Typography>
                        <Typography variant="body2" >
                            Простій (міс.):
                            {formatIdleTime(calculateDowntimeCheckpoints(selectedTruck.routes, 'currentMonth', true))}
                        </Typography>
                    </Grid>
                </Grid>}
                {(selectedTrip.truck.logistics_comment ||
                    selectedTrip.truck.tech_comment ||
                    selectedTrip.truck.hr_comment) &&
                    <Divider>
                        <Chip label="Коментарі до авто" />
                    </Divider>
                }
                <Grid container item direction={'column'} alignItems={'center'}>

                    {selectedTrip.truck.logistics_comment &&
                        <Grid item>
                            <Typography variant="caption" >
                                Коментар логістів:
                            </Typography>
                            <Typography variant="caption" fontFamily={'Roboto'}>
                                {selectedTrip.truck.logistics_comment}
                            </Typography>
                        </Grid>}
                    {selectedTrip.truck.tech_comment &&
                        <Grid item>
                            <Typography variant="caption" >Коментар тех. служби: </Typography>
                            <Typography variant="caption" fontFamily={'Roboto'}>
                                {selectedTrip.truck.tech_comment}
                            </Typography>
                        </Grid>}
                    {selectedTrip.truck.hr_comment &&
                        <Grid item>
                            <Typography variant="caption">Інша інфо.: </Typography>
                            <Typography variant="caption" fontFamily={'Roboto'}>
                                {selectedTrip.truck.hr_comment}
                            </Typography>
                        </Grid>}
                </Grid>
            </Grid>
            {selectedTrip && showCheckpoints &&
                <Grid item sm={6}>
                    <Box justifyContent={'center'}>
                        <Divider>
                            <Chip style={{ justifyContent: 'center' }} label='Статуси рейса' />
                        </Divider>
                        <CheckpointsList checkpoints={selectedTrip.checkpoints} isShowGPS={isShowGPS} />
                    </Box>
                </Grid>
            }
        </Grid >


    );
}

export default TripDetails;
