import { checkpointsList } from "../config";

const findCheckpointColor = (checkpointName) => {
    for (const stage of checkpointsList) {
      for (const checkpoint of stage.checkpoints) {
        if (checkpoint.name === checkpointName) {
          return checkpoint.color;
        }
      }
    }
    return null;
  };

  export {findCheckpointColor}