import React, { useState } from "react";
import { Grid, Typography, Button, ButtonGroup, Chip } from '@mui/material';
import { FaEdit, FaRegEye } from "react-icons/fa";
import "../../styles/VehicleTable.css";

const VehiclesTableRow = ({ vehicle, vehType }) => {

  const [vehicleData, setVehicleData] = useState(vehicle);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveVehicle = (formData) => {
    setVehicleData(formData);
    //  updateVehicle(formData);
  };

  return (
    <Grid
      container
      p={1}
      className={"vehiclesTableRow"}
      alignItems={'center'}
    >
      <Grid item sm={1}>
        <Typography variant="body1">
          {vehicleData.short_number}
        </Typography>
      </Grid>
      <Grid item sm={1} textAlign={'center'}>
        <Typography>{vehicleData.number}</Typography>
      </Grid>
      <Grid item sm={2} textAlign={'center'}>
        <Typography>{vehicleData.region_type}</Typography>
      </Grid>
      <Grid item sm={2} textAlign={'center'}>
        <Typography>{vehicleData.gpsProvider}</Typography>
      </Grid>
      <Grid item sm={2} textAlign={'center'}>
        <Typography>{vehicleData.gpsId}</Typography>
      </Grid>
      {/* <Grid item sm={3} textAlign={'center'}>
        <Grid container direction="column">
          {userData.clients.map((client) => (
            <Chip key={client._id} label={client.name} size="small" color="primary" />
          ))}
        </Grid>
      </Grid>
      <Grid item sm={1} textAlign={'center'}>
        <Typography variant="body1">{userData.driverLicense}</Typography>
      </Grid>
      <Grid item sm={1} textAlign={'right'}>
        <ButtonGroup>
          <Button variant="contained" onClick={handleOpenEditDialog}>
            <FaEdit />
          </Button>
          <EditUserDialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            user={userData}
            onSave={handleSaveUser}
          />
         
        </ButtonGroup>
      </Grid> */}
    </Grid>
  );
};

export default VehiclesTableRow;
