import { serverUrl } from "../config";
import axios from "axios";

const fetchTrucks = async () => {

    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${serverUrl}/api/routes/trucks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchTrucksPaginated = async (page, limit) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${serverUrl}/api/trucks/paginatedAll?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { totalPages: response.data.totalPages, vehicles: response.data.trucks };
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchRoutesByTruckId = async (truck_id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${serverUrl}/api/routes/trucks/` + truck_id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateTruck = async (truck) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(`${serverUrl}/api/trucks`, truck, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };



export {fetchTrucks, fetchRoutesByTruckId, updateTruck, fetchTrucksPaginated}