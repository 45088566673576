import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Box pt={7} pl={3}>
      <Typography variant="h2">Немає доступу!</Typography>
      <br />
      <Typography variant="body1">
        У вас немає доступу до цієї сторінки
      </Typography>
      <Box pt={2}>
        <Button variant="contained" color="primary" onClick={goBack}>
          Повернутися назад
        </Button>
      </Box>
    </Box>
  );
};

export default Unauthorized;
