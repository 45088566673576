import React, { useEffect, useState, useRef } from 'react';
import { fetchRoutesByTruckId, fetchTrucks } from '../api/TrucksApi';
import { List, ListItem, ListItemText, Grid, Typography, Box, useTheme, useMediaQuery, Button, Container, Switch, FormGroup, FormControlLabel, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import TripTooltip from '../components/TripTooltip';
import { formatDate, formatIdleTime } from '../utils/dateUtils';
import { calculateMileage, calculateDowntimeBetweenRoutes, calculateDowntimeCheckpoints, calculateDowntimeBetweenRoutesByLogistTime, calculateWorkingTime, getDateLimits } from '../utils/downtime'
const TruckDowntimeStatistics = () => {
  const [statistics, setStatistics] = useState(null);
  const [activeTruck, setActiveTruck] = useState(null);
  const [showList, setShowList] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lastSelectedTruckRef = useRef(null);

  const [dateRangeMode, setDateRangeMode] = useState('last30days');
  const [calcMode, setCalcMode] = useState(true);

  const handleTruckSelect = (truck) => {
    setActiveTruck(truck);
    if (isMobile) {
      setShowList(false);
    }
  }

  const handleBackToList = () => {
    setActiveTruck(null);
    setShowList(true);
    if (lastSelectedTruckRef.current) {
      setTimeout(() => {
        lastSelectedTruckRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100); // 100 мс задержки
    }
  }


  // const calculateDowntime = (routes) => {
  //   let downtime = 0;
  //   routes.forEach((route, index) => {
  //     if (index < routes.length - 1) { // Если это не последний рейс
  //       const unloadDate = new Date(route.unload_date);
  //       const nextLoadDate = new Date(routes[index + 1].load_date);
  //       downtime += nextLoadDate - unloadDate;
  //     }
  //   });

  //   return downtime;
  // };

  function renderDowntime(downtime) {
    if (downtime === null) return null;
    return (
      <ListItemText style={{ padding: '15px', backgroundColor: theme.palette.primary.main, flex: '1' }} primary={` Простій: ${formatIdleTime(downtime)}`} />
    );
  }

  // В компоненте:

  useEffect(() => {
    const fetchData = async () => {
      const trucks = await fetchTrucks();

      if (trucks) {
        const stats = await Promise.all(trucks.map(async item => {
          const { dateLimit, dateLimitMax } = getDateLimits(dateRangeMode);

          const routes = (await fetchRoutesByTruckId(item.truck._id)).filter(route => {
            const loadDate = new Date(route.load_date);
            // Додайте умову для перевірки, чи loadDate перевищує верхню межу dateLimitMax
            return loadDate >= dateLimit && loadDate <= dateLimitMax
          })
          if (routes && routes.length > 0) {
            // const downtime = calculateDowntime(routes);
            const downtimeCheckpoints = calculateDowntimeCheckpoints(routes, dateRangeMode, calcMode);
            const mileage = calculateMileage(routes);
            return { truck: item.truck, mileage, downtimeCheckpoints, routes: routes, driver: routes[routes.length - 1].driver };
          }
          // Если маршрутов нет, возвращаем статистику с простоем 0 для данного грузовика
          return { truck: item.truck, downtime: 0, mileage: 0, downtimeCheckpoints: calculateWorkingTime(dateLimit, dateLimitMax), routes: [] };
        }));

        setStatistics(stats);
      }
    };
    setStatistics(null);
    fetchData();
  }, [dateRangeMode, calcMode]);
  // const theme = useTheme();
  // const Title = styled(Typography)({

  // });


  if (!statistics) {
    return <Box height={'100vh'} pl={3} pt={10}>
      <Typography variant='h3'>Завантаження...</Typography>
    </Box>
  }

  return (
    <Box height={'100vh'} pt={4}>
      <Box
        pl={1}
        pt={2}
        height="auto"
        position="fixed"
        width="100%"
        zIndex={100}
        bgcolor={theme.palette.primary.main}
      >
        <Typography variant="h4" color={theme.palette.secondary.main}>
          Статистика по авто

        </Typography>
      </Box>
      <Box maxWidth={'false'} pt={9} pb={1} pl={1}>
        {/* <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={isLastThirtyDays}
                onChange={(event) => setIsLastThirtyDays(event.target.checked)}
                name="dateRangeSwitch"
                color="primary"
              />
            }
            label={isLastThirtyDays ? 'Останні 30 днів' : 'Поточний місяць'}
          />
          <FormControlLabel
            control={
              <Switch
                checked={calcMode}
                onChange={(event) => setCalcMode(event.target.checked)}
                name="dateRangeSwitch"
                color="primary"
              />
            }
            label={calcMode ? 'По часу логістів' : 'По відміткам водіїв'}
          />
        </FormGroup> */}
        <FormGroup row>
          <FormControl variant="outlined">
            <InputLabel id="dateRangeLabel">Період</InputLabel>
            <Select
              labelId="dateRangeLabel"
              id="dateRangeSelect"
              value={dateRangeMode}
              onChange={(event) => setDateRangeMode(event.target.value)}
              label="Дата"
            >
              <MenuItem value={'last30days'}>Останні 30 днів</MenuItem>
              <MenuItem value={'currentMonth'}>Поточний місяць</MenuItem>
              <MenuItem value={'prevMonth'}>Минулий місяць</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="calcModeLabel">Режим</InputLabel>
            <Select
              labelId="calcModeLabel"
              id="calcModeSelect"
              value={calcMode}
              onChange={(event) => setCalcMode(event.target.value)}
              label="Режим"
            >
              <MenuItem value={true}>По часу логістів</MenuItem>
              <MenuItem value={false}>По відміткам водіїв</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
      </Box>

      <Grid container spacing={2} style={{ height: '95%' }}>

        {showList && (
          <Grid item xs={12} sm={8} md={8} lg={8} xl={6} style={{ height: '95%', overflow: 'auto' }}>
            {statistics ? (
              <Container style={{ height: '100%' }} disableGutters >
                <Typography variant="h6" pl={1} color={'secondary'}>Міжнародні авто</Typography>
                <List>
                  {statistics
                    .filter((item) => item.truck.region_type === 'Міжнародне авто В' ||
                      item.truck.region_type === 'Міжнародне авто I')
                    .sort((a, b) => b.downtimeCheckpoints - a.downtimeCheckpoints)
                    .map((stat, index) => (
                      <ListItem
                        style={activeTruck && stat.truck._id === activeTruck.truck._id ?
                          { backgroundColor: theme.palette.primary.main } : {}}
                        key={index}
                        onClick={() => handleTruckSelect(stat)}
                        ref={activeTruck && stat.truck._id === activeTruck.truck._id ?
                          lastSelectedTruckRef : null} // Устанавливаем ссылку на выбранный элемент
                      >
                        <Grid container>
                          <Grid item container xs={8}>
                            <Grid item xs={2}>
                              <ListItemText primary={`${stat.truck.short_number}`} />
                            </Grid>
                            <Grid item xs={4}>
                              <ListItemText primary={`${stat.truck.number}`} />
                            </Grid>
                            <Grid item xs={2}>
                              {stat.driver &&
                                <ListItemText
                                  primary={`${stat.driver?.last_name} ${stat.driver?.first_name[0]}.`} />
                              }
                            </Grid>
                            <Grid item xs={4}>
                              {stat.driver &&
                                <ListItemText
                                  primary={stat.driver?.lastCheckpointUpdate ? formatDate(stat.driver.lastCheckpointUpdate) : ''}
                                />
                              }
                            </Grid>
                          </Grid>
                          <Grid item container xs={4}>
                            <Grid item xs={6}>
                              <ListItemText primary={`${stat.mileage} км.`} />
                            </Grid>
                            <Grid item xs={6}>
                              <ListItemText
                                primary={`${formatIdleTime(stat.downtimeCheckpoints)}`} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </List>
                <Typography variant="h6" pl={1} color={'secondary'}>Україна</Typography>
                <List>
                  {statistics
                    .filter((item) => item.truck.region_type === 'Україна')
                    .sort((a, b) => b.downtimeCheckpoints - a.downtimeCheckpoints)
                    .map((stat, index) => (
                      <ListItem
                        style={activeTruck && stat.truck._id === activeTruck.truck._id ?
                          { backgroundColor: theme.palette.primary.main } : {}}
                        key={index}
                        onClick={() => handleTruckSelect(stat)}
                        ref={activeTruck && stat.truck._id === activeTruck.truck._id ?
                          lastSelectedTruckRef : null} // Устанавливаем ссылку на выбранный элемент
                      >
                        <Grid container>
                          <Grid item container xs={8}>
                            <Grid item xs={2}>
                              <ListItemText primary={`${stat.truck.short_number}`} />
                            </Grid>
                            <Grid item xs={4}>
                              <ListItemText primary={`${stat.truck.number}`} />
                            </Grid>
                            <Grid item xs={2}>
                              {stat.driver &&
                                <ListItemText
                                  primary={`${stat.driver?.last_name} ${stat.driver?.first_name[0]}.`} />
                              }
                            </Grid>
                            <Grid item xs={4}>
                              {stat.driver &&
                                <ListItemText
                                  primary={stat.driver?.lastCheckpointUpdate ? formatDate(stat.driver.lastCheckpointUpdate) : ''}
                                />
                              }
                            </Grid>
                          </Grid>
                          <Grid item container xs={4}>
                            <Grid item xs={6}>
                              <ListItemText primary={`${stat.mileage} км.`} />
                            </Grid>
                            <Grid item xs={6}>
                              <ListItemText
                                primary={`${formatIdleTime(stat.downtimeCheckpoints)}`} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </List>
                <Typography variant="h6" pl={1} color={'secondary'}>Інші</Typography>
                <List >
                  {statistics
                    .filter((item) => item.truck.region_type !== 'Україна' &&
                      item.truck.region_type !== 'Міжнародне авто В' &&
                      item.truck.region_type !== 'Міжнародне авто I')
                    .sort((a, b) => b.downtimeCheckpoints - a.downtimeCheckpoints)
                    .map((stat, index) => (
                      <ListItem
                        style={
                          activeTruck &&
                            stat.truck._id === activeTruck.truck._id ?
                            { backgroundColor: theme.palette.primary.main } : {}}
                        key={index}
                        onClick={() => handleTruckSelect(stat)}
                        ref={activeTruck && stat.truck._id === activeTruck.truck._id ?
                          lastSelectedTruckRef : null} // Устанавливаем ссылку на выбранный элемент
                      >
                        <Grid container>
                          <Grid item container xs={8}>
                            <Grid item xs={2}>
                              <ListItemText primary={`${stat.truck.short_number}`} />
                            </Grid>
                            <Grid item xs={4}>
                              <ListItemText primary={`${stat.truck.number}`} />
                            </Grid>
                            <Grid item xs={2}>
                              {stat.driver &&
                                <ListItemText
                                  primary={`${stat.driver?.last_name} ${stat.driver?.first_name[0]}.`} />
                              }
                            </Grid>
                            <Grid item xs={4}>
                              {stat.driver &&
                                <ListItemText
                                  primary={stat.driver?.lastCheckpointUpdate ? formatDate(stat.driver.lastCheckpointUpdate) : ''}
                                />
                              }
                            </Grid>
                          </Grid>
                          <Grid item container xs={4}>
                            <Grid item xs={6}>
                              <ListItemText primary={`${stat.mileage} км.`} />
                            </Grid>
                            <Grid item xs={6}>
                              <ListItemText
                                primary={`${formatIdleTime(stat.downtimeCheckpoints)}`} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </List>
              </Container>
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
        )}
        {(!isMobile || !showList) && activeTruck && (
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ height: '100%' }}>
            {/* Добавляем кнопку "Назад" для мобильных устройств */}
            {isMobile && (
              <Box pt={3}>
                <Button variant="contained" color="primary" onClick={handleBackToList}>
                  Назад
                </Button>
              </Box>
            )}
            <Typography variant="h6" pl={1} color={'secondary'} pt={1}>Рейси авто</Typography>
            <List style={{ paddingBottom: '30px', height: '100%' }}>
              {activeTruck.routes
                .flatMap((route, index) => {
                  let downtime = index > 0 ? (calcMode ?
                    calculateDowntimeBetweenRoutesByLogistTime(
                      activeTruck.routes[index - 1], route, dateRangeMode) :
                    calculateDowntimeBetweenRoutes(
                      activeTruck.routes[index - 1], route, dateRangeMode)) :
                    (calcMode ? calculateDowntimeBetweenRoutesByLogistTime(
                      null, route, dateRangeMode) :
                      calculateDowntimeBetweenRoutes(null, route, dateRangeMode));

                  return [
                    downtime && (
                      <ListItem key={`downtime-${index}`} disablePadding>
                        {renderDowntime(downtime)}
                      </ListItem>
                    ),
                    <ListItem key={`route-${index}`} disablePadding>
                      <TripTooltip
                        key={route._id}
                        trip={route}
                        selectedTruck={activeTruck.truck._id}
                      />
                    </ListItem>
                  ];
                })}

              {
                activeTruck.routes.length > 0 &&
                activeTruck.routes[activeTruck.routes.length - 1]
                  .checkpoints[activeTruck.routes[activeTruck.routes.length - 1]
                    .checkpoints.length - 1].name === 'Рейс завершено' && (
                  <ListItem key={`downtime-${activeTruck.routes.length}`} >
                    {renderDowntime(calcMode ?
                      calculateDowntimeBetweenRoutesByLogistTime(
                        activeTruck.routes[activeTruck.routes.length - 1], null, dateRangeMode) :
                      calculateDowntimeBetweenRoutes(
                        activeTruck.routes[activeTruck.routes.length - 1], null, dateRangeMode))}
                  </ListItem>
                )
              }

              {
                activeTruck.routes.length > 0 &&
                activeTruck.routes[activeTruck.routes.length - 1]
                  .checkpoints[activeTruck.routes[activeTruck.routes.length - 1]
                    .checkpoints.length - 1].name !== 'Рейс завершено' &&
                (<ListItem key={`downtime-${activeTruck.routes.length}`} >
                  {renderDowntime(calcMode ?
                    calculateDowntimeBetweenRoutesByLogistTime(
                      activeTruck.routes[activeTruck.routes.length - 1], null
                    ) : null)}
                </ListItem>)
              }

            </List>

          </Grid>
        )}
      </Grid>
    </Box>

  );
};

export default TruckDowntimeStatistics;

