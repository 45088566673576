import React from 'react';
import { FaAngleDoubleUp, FaRuler, FaTemperatureLow, FaUser } from 'react-icons/fa';
import { formatDate } from '../utils/dateUtils';
import { Box, Divider, Typography } from '@mui/material';
import '../styles/CheckpointsList.css';

const CheckpointsList = ({ checkpoints, isShowGPS }) => {
  const sortedCheckpoints = [...checkpoints].sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  const renderItem = ({ item, index }) => {
    let duration = null;
    let prevItem;
    if (index > 0) {
      prevItem = sortedCheckpoints[index - 1];
    } else {
      prevItem = { date: new Date() };
    }
    const diff = new Date(prevItem.date).getTime() - new Date(item.date).getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    duration = `${days} д. ${hours} г. ${minutes} хв.`;

    return (
      <React.Fragment key={index}>
        <Box maxWidth={'false'} key={index} className={`item ${index === 0 ? 'firstItem' : ''}`}>
          <FaAngleDoubleUp size={20} color="#e996a6" className="icon" />
          <Box lineHeight={1}>
            <Typography variant="caption" fontWeight={'500'}>{item.name}</Typography>
            {item.user ?
              (<Box className="row">
                <Typography variant="caption"><FaUser color='#e996a6' style={{ paddingRight: "2px" }} />{item.user}</Typography>
              </Box>) : (null)
            }

            {isShowGPS ? (
              <> <Box className="row" justifyContent={'left'}>
                {item.vehicleGPS?.temperature ? (
                 
                    <Typography variant="caption">
                      <FaTemperatureLow color='#e996a6' style={{ paddingRight: "2px" }} />
                      {item.vehicleGPS?.temperature}
                    </Typography>
                 
                ) : null}
                {item.vehicleGPS?.mileage ? (
                 
                    <Typography paddingLeft={2} variant="caption" >
                    <FaRuler color="green" title="Пробіг з початку рейса по даним GPS" /> 
                      {item.vehicleGPS?.mileage} км
                    </Typography>
                
                ) : null}
                  </Box>
                <Box className="row">
                  <Typography variant="caption" fontStyle={'italic'} color={'grey'}>
                    {item.vehicleGPS?.address}
                  </Typography>
                </Box>
                
              </>
            ) : null}


            <Box className="row">
              <Typography variant="caption">
                {formatDate(item.date)}, {duration &&
                  <b className='no-margin-bottom'>{duration}</b>}
              </Typography>
            </Box>

          </Box>

        </Box>
        <Divider />
      </React.Fragment>


    );
  };

  return (
    <Box className="list">
      {sortedCheckpoints.map((item, index) => renderItem({ item, index }))}
    </Box>
  );
};

export default CheckpointsList;
