import { TextField, InputAdornment } from '@mui/material';
import { FaSearch } from 'react-icons/fa';
import React from 'react';

function SearchBar({ onSearch }) {
  const handleChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <TextField 
      placeholder="Пошук..."
      variant="outlined"
      onChange={handleChange}
      sx={{ height: '30px', minHeight: '30px' }}
      InputProps={{
        style: { height: '30px'},
        endAdornment: (
          <InputAdornment position="end">
            <FaSearch />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
