import axios from "axios";
import { serverUrl } from "../config";

const fetchClients = async () => {

    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${serverUrl}/api/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  export {fetchClients};