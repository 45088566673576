

const calculateDowntimeCheckpoints = (routes, dateRangeMode, calcMode) => {
    let calcFunction = calcMode === true ?
        calculateDowntimeBetweenRoutesByLogistTime : calculateDowntimeBetweenRoutes;
    let totalDowntime = 0;
    const { dateLimit, dateLimitMax } = getDateLimits(dateRangeMode);
    let routesToCalc = routes.filter(route => {
        const loadDate = new Date(route.load_date);
        // Додайте умову для перевірки, чи loadDate перевищує верхню межу dateLimitMax
        return loadDate >= dateLimit && loadDate <= dateLimitMax
    })
        .sort((a, b) => new Date(a.load_date) - new Date(b.load_date));
    // console.log(routesToCalc);

    if (routesToCalc.length === 0)
        return (calculateWorkingTime(dateLimit, dateLimitMax));

    routesToCalc.forEach((route, index) => {
        if (index === 0) {
            totalDowntime += calcFunction(null, route, dateRangeMode);
        }
        else if (index > 0 && index !== routesToCalc.length - 1) {
            totalDowntime += calcFunction(routesToCalc[index - 1], route, dateRangeMode);
        }
        else if (index === routesToCalc.length - 1) { // змінено тут
            if (route.checkpoints[route.checkpoints.length - 1].name === "Рейс завершено") {
                totalDowntime += calcFunction(route, null, dateRangeMode);
            }
            if (routesToCalc.length > 1) {
                totalDowntime += calcFunction(routesToCalc[index - 1], route, dateRangeMode);
            }
        }
    });

    return totalDowntime;
};

function calculateDowntimeBetweenRoutes(prevRoute, nextRoute, dateRangeMode) {
    let prevEndDate;
    let nextStartDate;
    const { dateLimit, dateLimitMax } = getDateLimits(dateRangeMode);

    if (!prevRoute && !nextRoute) {
        return calculateWorkingTime(dateLimit, dateLimitMax);
    }

    // Если nextRoute отсутствует, рейс завершен, и мы используем текущую дату и время
    if (!nextRoute) {
        prevEndDate = new Date(prevRoute.checkpoints[prevRoute.checkpoints.length - 1].date);
        nextStartDate = dateLimitMax;
    } else if (!prevRoute) {
        prevEndDate = dateLimit;
        nextStartDate = nextRoute.checkpoints.find(checkpoint =>
            checkpoint.name !== "Рейс створено, водій ще не прийняв" &&
            checkpoint.name !== "Водій прийняв рейс" &&
            checkpoint.name !== "Рейс завершено");
        nextStartDate = nextStartDate ?
            new Date(nextStartDate.date) : new Date(nextRoute.load_date)
    }
    else {
        prevEndDate = prevRoute.checkpoints[prevRoute.checkpoints.length - 1].name === 'Рейс завершено' ? prevRoute.checkpoints[prevRoute.checkpoints.length - 1] : null;
        prevEndDate = prevEndDate ?
            new Date(prevEndDate.date) : new Date(prevRoute.unload_date);

        nextStartDate = nextRoute.checkpoints.find(checkpoint =>
            checkpoint.name !== "Рейс створено, водій ще не прийняв" &&
            checkpoint.name !== "Водій прийняв рейс" &&
            checkpoint.name !== "Рейс завершено");
        nextStartDate = nextStartDate ?
            new Date(nextStartDate.date) : new Date(nextRoute.load_date)
    }


    if (dateLimitMax < nextStartDate) {
        let dif = dateLimitMax - prevEndDate;
        if (dif < 0) {
            return 1;
        }
        return calculateWorkingTime(prevEndDate, dateLimitMax);
    }

    let dowtime = calculateWorkingTime(prevEndDate, nextStartDate);
    return dowtime > 0 ? dowtime : 1;
}

function calculateDowntimeBetweenRoutesByLogistTime(prevRoute, nextRoute, dateRangeMode) {
    let prevEndDate;
    let nextStartDate;
    const { dateLimit, dateLimitMax } = getDateLimits(dateRangeMode);

    if (!prevRoute && !nextRoute) {
        return calculateWorkingTime(dateLimit, dateLimitMax);
    }

    // Если nextRoute отсутствует, рейс завершен, и мы используем текущую дату и время
    if (!nextRoute) {
        prevEndDate = new Date(prevRoute.unload_date);
        nextStartDate = dateLimitMax;
    } else if (!prevRoute) {
        prevEndDate = dateLimit;
        nextStartDate = new Date(nextRoute.load_date);
    }
    else {
        prevEndDate = new Date(prevRoute.unload_date);
        nextStartDate = new Date(nextRoute.load_date)
    }


    if (dateLimitMax < nextStartDate) {
        let dif = dateLimitMax - prevEndDate;
        if (dif < 0) {
            return 1;
        }
        return calculateWorkingTime(prevEndDate, dateLimitMax);
    }
    // let dowtime = nextStartDate - prevEndDate;
    let dowtime = calculateWorkingTime(prevEndDate, nextStartDate);
    return dowtime > 0 ? dowtime : 1;
}

function calculateWorkingTime(start, end) {
    // рабочее время от 8 до 17
    const workStart = 7;
    const workEnd = 22;

    let totalWorkTime = 0;

    // текущий день для перебора дней
    let currentDay = new Date(start);

    while (currentDay <= end) {
        // вычисляем начало и конец рабочего дня
        let currentDayStart = new Date(currentDay);
        currentDayStart.setHours(workStart, 0, 0, 0);

        let currentDayEnd = new Date(currentDay);
        currentDayEnd.setHours(workEnd, 0, 0, 0);

        // проверяем, что начало и конец рабочего времени попадают в рабочий день
        if (start <= currentDayEnd && end >= currentDayStart) {
            // устанавливаем начало рабочего дня, если оно позднее начала рабочего времени
            if (start > currentDayStart) {
                currentDayStart = start;
            }

            // устанавливаем конец рабочего дня, если он раньше конца рабочего времени
            if (end < currentDayEnd) {
                currentDayEnd = end;
            }

            // вычисляем продолжительность рабочего времени и добавляем к общему времени (в миллисекундах)
            totalWorkTime += currentDayEnd - currentDayStart;
        }


        // переходим к следующему дню
        currentDay.setHours(24, 0, 0, 0);
    }

    return totalWorkTime;
}


const calculateMileage = (routes) => {
    let mileage = 0;
    routes.forEach((route) => {
        if (route.checkpoints[route.checkpoints.length - 1].name === 'Рейс завершено') {
            mileage += route.distance;
        }
    })
    return mileage;
}

function getDateLimits(dateRangeMode) {
    const dateLimit = new Date();
    const dateLimitMax = new Date();
  
    switch (dateRangeMode) {
      case 'last30days':
        dateLimit.setDate(dateLimit.getDate() - 30);
        break;
      case 'currentMonth':
        dateLimit.setDate(1); // якщо вибраний поточний місяць
        dateLimit.setHours(0, 0, 0, 0);
        break;
      case 'prevMonth':
        dateLimit.setMonth(dateLimit.getMonth() - 1);
        dateLimit.setDate(1); // Перший день попереднього місяця
        dateLimit.setHours(0, 0, 0, 0);
  
        dateLimitMax.setDate(1); // Перший день поточного місяця
        dateLimitMax.setHours(0, 0, 0, 0);
        dateLimitMax.setDate(dateLimitMax.getDate() - 1);
        break;
      default:
        break;
    }
  
    return { dateLimit, dateLimitMax };
  } 

export {
    calculateMileage,
    calculateDowntimeBetweenRoutes,
    calculateDowntimeCheckpoints,
    calculateDowntimeBetweenRoutesByLogistTime,
    calculateWorkingTime,
    getDateLimits
}