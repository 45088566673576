import React, { useState, useMemo, useEffect } from "react";
import MapWithRoute from "../components/MapWithRoute";
import { fetchTrucks, fetchRoutesByTruckId } from "../api/TrucksApi";
import SearchBar from "../components/SearchBar";
import "../styles/TruckList.css"
import TruckTrips from "../components/TruckTripsList";
import TrucksListItem from "../components/TrucksListItem";
import { Container, List, Box, Grid, Typography, Paper, Button } from '@mui/material';
import { FaLocationArrow } from 'react-icons/fa';

import CheckpointsList from "../components/CheckpointList";
import TripDetailsBlock from "../components/TripDetailsBlock";

function Trucks() {
    const [trucks, setTrucks] = useState([]);
    const optimizedTrucks = useMemo(() => trucks, [trucks]);
    const [filteredTrucks, setFilteredTrucks] = useState([]);

    const [selectedTruck, setSelectedTruck] = useState(null);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [showCurrentPosition, setShowCurrentPosition] = useState(false);

    const handleSearch = (value) => {
        let filteredTrucks = optimizedTrucks.filter((item) =>
            item.truck.short_number.includes(value) || (item.routes.length > 0 && item.routes[0].driver.last_name.toLowerCase().includes(value.toLowerCase())) ||
            (item.last_route && item.last_route.driver.last_name.toLowerCase().includes(value.toLowerCase()))
        );

        setFilteredTrucks(filteredTrucks);
    };

    const fetchTrucksData = async () => {
        const fetchedTrucks = await fetchTrucks();
        setTrucks(fetchedTrucks);
    };

    useEffect(() => {
        fetchTrucksData();

        // Запускаем интервал, который будет вызывать fetchTrucksData каждую минуту
        const intervalId = setInterval(() => {
            fetchTrucksData();
        }, 60000); // 60000 миллисекунд = 1 минута
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    useEffect(() => {
        // Обновляем filteredTrucks при изменении optimizedTrucks
        setFilteredTrucks(optimizedTrucks);
    }, [optimizedTrucks]);

    const handleSelectTruck = async (truck) => {
        setSelectedTrip(null);
        let routes = await fetchRoutesByTruckId(truck._id);
        let filteredTrips = routes.sort((a, b) => new Date(a.load_date) - new Date(b.load_date));
        const firstNotCompletedRoute = filteredTrips
            .find(route =>
                route.checkpoints[route.checkpoints.length - 1].name !== 'Рейс завершено');
        // Сортируем массив routes по полю load_date
        routes.sort((a, b) => new Date(b.load_date) - new Date(a.load_date));

        let updatedTruck = {
            truck: truck,
            routes: routes,
        };
        setSelectedTruck(updatedTruck);
        setSelectedTrip(firstNotCompletedRoute ? firstNotCompletedRoute : updatedTruck.routes[0]);
        setShowCurrentPosition(false);
    };

    const handleAddCheckpoint = async (trip) => {
        if (selectedTrip._id === trip._id) {
            setSelectedTrip(trip);
            handleSelectTruck(trip.truck);
            await fetchTrucksData();
        }
    }

    const handleEditTrip = async (trip) => {
        if (trip) {
            if (selectedTrip._id === trip._id) {
                setSelectedTrip(trip);
                handleSelectTruck(trip.truck);
                await fetchTrucksData();
            }
        } else {
            setSelectedTrip(null);
            await fetchTrucksData();
        }
    }

    return (
        <Container
            maxWidth={'false'}
            sx={{
                paddingTop: 6,
                height: '100vh'
            }} >
            <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid
                    item
                    sm={3}
                    style={{ paddingLeft: '5px', height: '100%' }} >
                    {/* Список вантажівок */}
                    <Paper style={{ height: '100%' }}>
                        <Box
                            height={'50px'}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between" p={1}>
                            <Typography variant="h6" color={'primary'}>
                                Список вантажівок
                            </Typography>
                            <SearchBar onSearch={handleSearch} />
                        </Box>
                        <Box
                            pt={2}
                            style={{
                                height: 'calc(100vh - 116px)',
                                overflow: 'auto'
                            }}>
                            <Typography variant="h6" pl={1} color={'secondary'}>
                                Міжнародні авто
                            </Typography>
                            <List >
                                {filteredTrucks
                                    .filter((item) =>
                                        item.truck.region_type === 'Міжнародне авто В' ||
                                        item.truck.region_type === 'Міжнародне авто I')
                                    .map((item) => (
                                        <TrucksListItem
                                            selectedTruck={selectedTruck}
                                            key={item.truck._id}
                                            handleSelectTruck={handleSelectTruck}
                                            item={item} />
                                    ))}
                            </List>
                            <Typography
                                variant="h6"
                                pl={1}
                                color={'secondary'}>Україна
                            </Typography>
                            <List>
                                {filteredTrucks
                                    .filter((item) => item.truck.region_type === 'Україна')
                                    .map((item) => (
                                        <TrucksListItem
                                            selectedTruck={selectedTruck}
                                            key={item.truck._id}
                                            handleSelectTruck={handleSelectTruck}
                                            item={item} />
                                    ))}
                            </List>
                            <Typography variant="h6" pl={1} color={'secondary'}>
                                Інші
                            </Typography>
                            <List>
                                {filteredTrucks
                                    .filter((item) =>
                                        item.truck.region_type !== 'Україна' &&
                                        item.truck.region_type !== 'Міжнародне авто В' &&
                                        item.truck.region_type !== 'Міжнародне авто I')
                                    .map((item) => (
                                        <TrucksListItem
                                            selectedTruck={selectedTruck}
                                            key={item.truck._id}
                                            handleSelectTruck={handleSelectTruck}
                                            item={item} />
                                    ))}
                            </List>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm={3}>
                    {selectedTruck &&
                        <TripDetailsBlock
                            selectedTrip={selectedTrip}
                            selectedTruck={selectedTruck}
                            handleEditTrip={handleEditTrip}
                            handleAddCheckpoint={handleAddCheckpoint}
                            showIdle={true}
                        />
                    }

                </Grid>
                <Grid item sm={3}>
                    {selectedTruck &&
                        <>
                            <Paper>
                                <Typography
                                    variant="h5"
                                    style={{
                                        padding: '10px',
                                        borderRadius: '10px'
                                    }}
                                    color={'primary'}>
                                    Статуси рейса
                                </Typography>
                                <Box height={'calc(30vh - 50px)'}
                                    overflow={'auto'}>
                                    {selectedTrip &&
                                        <CheckpointsList checkpoints={selectedTrip.checkpoints} isShowGPS={true} />}
                                </Box>
                            </Paper>
                            <Paper>
                                <Typography
                                    variant="h5"
                                    style={{
                                        padding: '10px',
                                        borderRadius: '10px'
                                    }}
                                    color={'primary'}>Чат з водієм</Typography>
                            </Paper>
                        </>
                    }

                </Grid>

                <Grid container item sm={3} direction={'column'} height={'100%'}>
                    <Grid item height={'40%'}>
                        {selectedTruck &&
                            <Paper style={{ height: '100%', overflow: 'auto' }}>
                                <TruckTrips
                                    handleAddCheckpoint={handleAddCheckpoint}
                                    handleEditTrip={handleEditTrip}
                                    selectedTrip={selectedTrip}
                                    selectedTruck={selectedTruck}
                                    setSelectedTrip={setSelectedTrip}
                                    setShowCurrentPosition={setShowCurrentPosition}
                                />
                            </Paper>
                        }

                    </Grid>
                    <Grid item height={'60%'}>
                        {selectedTrip && (
                            <Paper style={{ height: '100%' }}>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-around'}>
                                    <Typography variant="h5"
                                        style={{ padding: '10px' }}
                                        color={'primary'}>
                                        Мапа рейса
                                    </Typography>
                                    <Button title="Поточна геолокація"
                                        variant="contained"
                                        color='primary'
                                        style={{ height: '30px', width: '10px' }}
                                        onClick={() => { setShowCurrentPosition(true) }}>
                                        <FaLocationArrow size={15} />
                                    </Button>
                                </Box >
                                <Box height={'calc(100% - 53px)'}>
                                    {showCurrentPosition ?
                                        <MapWithRoute trip={{
                                            load_date: new Date(),
                                            unload_date: new Date(),
                                            checkpoints: [{ name: 'Поточна локація' }],
                                            truck: selectedTruck.truck,
                                            trailer: selectedTruck.routes[0].trailer
                                        }} /> :
                                        <MapWithRoute trip={selectedTrip} />}
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Trucks;