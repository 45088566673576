import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import ReactPaginate from "react-paginate";
import "../../styles/pagination.css";
import { fetchClientTrips } from "../../api/TripsApi";
import ClientTripsTableRow from "./ClientTripsTableRow";
import { FaAngleLeft, FaAngleRight, FaInfoCircle } from "react-icons/fa";
const ClientTripsTable = () => {

    const [trips, setTrips] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 15;

    const fetchTripsPage = async (page, limit) => {
        const fetchedTrips = await fetchClientTrips(page, limit);
        setTrips(fetchedTrips.trips);
        setTotalPages(fetchedTrips.totalPages);
    }

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected + 1);

    }


    useEffect(() => {
        fetchTripsPage(page, limit);
    }, [page, limit]);

    if (!trips) {
        return <Box height={'100vh'} pl={3} pt={10}>
            <Typography variant='h3'>Завантаження...</Typography>
        </Box>
    }

    return <Container disableGutters>
        <Grid container pb={1} alignItems={'center'} pr={2} className="clientTripsTableColumnHeader">

            <Grid item sm={1} >
                <Typography variant="body2" textAlign={'center'}>Дати</Typography>
            </Grid>
            <Grid container item sm={5} >
                <Grid item sm={6} >
                    <Typography variant="body2" textAlign={'center'}>Адреса завантаження</Typography>
                </Grid>
                <Grid item sm={6} >
                    <Typography variant="body2" textAlign={'center'}>Адреса розвантаження</Typography>
                </Grid>
            </Grid>

            <Grid item sm={1} >
                <Typography variant="body2" textAlign={'center'}>Водій</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="body2" textAlign={'center'}>Транспорт</Typography>
            </Grid>
            <Grid item sm={2}>
                <Typography variant="body2" textAlign="center">GPS</Typography>
            </Grid>
            <Grid container item sm={2} >
                <Grid item sm={10}>
                    <Typography variant="body2" textAlign={'center'}>Статус</Typography>
                </Grid>
                <Grid item sm={2}>
                    <FaInfoCircle />
                </Grid>
            </Grid>

        </Grid>
        <Grid container overflow={'auto'} maxHeight={450} >
            {trips.map((trip, index) => (
                <ClientTripsTableRow trip={trip} key={trip._id} />
            ))}
        </Grid>
        <Box mt={3} pb={3} >
            <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"

                previousClassName={"pagination-prev"} // Клас для кнопки "Попередня"
                nextClassName={"pagination-next"} // Клас для кнопки "Наступна"
                previousLinkClassName={"pagination-prev-link"} // Клас для посилання кнопки "Попередня"
                nextLinkClassName={"pagination-next-link"} // Клас для посилання кнопки "Наступна"
                previousPageClassName={"pagination-prev-page"} // Клас для кнопки "Попередня" на окремій сторінці
                nextPageClassName={"pagination-next-page"} // Клас для кнопки "Наступна" на окремій сторінці
                breakLabel={"..."}
                breakClassName={"pagination-break"}
                pageClassName={"pagination-page"} // Клас для сторінки
                pageLinkClassName={"pagination-page-link"} // Клас для посилання сторінки
                activeLinkClassName={"pagination-active-link"} // Клас для активної сторінки
                previousLabel={<FaAngleLeft />} // Встановлюємо іконку для "Попередня"
                nextLabel={<FaAngleRight />} // Встановлюємо іконку для "Наступна"
            />
        </Box>
    </Container>

};

export default ClientTripsTable;