import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import ReactPaginate from "react-paginate";
import "../../styles/pagination.css";
import { fetchTrucksPaginated } from "../../api/TrucksApi";
import { fetchTrailersPaginated } from "../../api/TrailersApi";
import VehiclesTableRow from "./VehiclesTableRow";
const VehiclesTable = ({ vehType }) => {

    const [vehicles, setVehicles] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 15;

    const fetchVehiclesPage = async (vehType, page, limit) => {
        const fetchedVehicles = vehType === "trucks" ? await fetchTrucksPaginated(page, limit) : await fetchTrailersPaginated(page, limit);
        setVehicles(fetchedVehicles.vehicles);
        setTotalPages(fetchedVehicles.totalPages);
    }

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage.selected + 1);

    }

    useEffect(() => {
        fetchVehiclesPage(vehType, page, limit);
    }, [page, limit, vehType]);

    if (!vehicles) {
        return <Box height={'100vh'} pl={3} pt={10}>
            <Typography variant='h3'>Завантаження...</Typography>
        </Box>
    }

    return <Container disableGutters>
        <Grid container pb={1}>
            <Grid item sm={1} textAlign={'center'}>
                <Typography variant="h6" textAlign="center">#</Typography>
            </Grid>


            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Номер</Typography>
            </Grid>
            <Grid item sm={2} >
                <Typography variant="h6" textAlign={'center'}>Регіон</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>GPS</Typography>
            </Grid>
            <Grid item sm={3} >
                <Typography variant="h6" textAlign={'center'}>GPS ID</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Опис</Typography>
            </Grid>
            <Grid item sm={1} >
                <Typography variant="h6" textAlign={'center'}>Параметри</Typography>
            </Grid>
        </Grid>
        <Grid container overflow={'auto'} maxHeight={450}>
            {vehicles.map((vehicle, index) => (
                <VehiclesTableRow vehicle={vehicle} vehType={vehType} key={vehicle._id} />

            ))}
        </Grid>
        <Box mt={3} pb={3}>
            <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel="Попередня"
                nextLabel="Наступна"
            />
        </Box>
    </Container>

};

export default VehiclesTable;