import { serverUrl } from "../config";
import axios from "axios";

const fetchUsers = async () => {

  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const fetchUsersPaginated = async (page, limit) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/users/paginatedAll?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { totalPages: response.data.totalPages, users: response.data.users };
  } catch (error) {
    console.log(error);
    return null;
  }
};


const fetchDrivers = async () => {

  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/users/drivers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getUserPassword = async (user_id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/users/password/${user_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.code;
  } catch (error) {
    console.log(error);
    return null;
  }
}

const updateUser = async (user) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.put(`${serverUrl}/api/users/`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Помилка від сервера:', error.response.data);
    } else if (error.request) {
      console.error('Помилка під час відправки запиту:', error.request);
    } else {
      console.error('Помилка:', error.message);
    }
    return null;
  }
  
}

export { fetchUsers, fetchDrivers, fetchUsersPaginated, getUserPassword, updateUser }