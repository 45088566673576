import React, { useState } from "react";
import { Box, Container, Divider, Grid, List, ListItemButton, Paper, Typography, useTheme } from '@mui/material';
import "../styles/pagination.css";
import UsersTable from "../components/UsersTable/UsersTable";
import ClientTripsTable from "../components/ClientTrips/ClientTripsTable";
const ClientPage = () => {
    const theme = useTheme();

    return <Container  maxWidth={'true'} sx={{paddingTop: 8, height: '100vh' }} >
       
        <Grid container>
            <Grid item sm={12} xs={12}>
                <Paper>
                    <Box>
                        <Grid container alignItems={'center'}>
                            {/* <Grid item>
                                <Typography variant="h4" pb={1} pl={2}>Користувачі</Typography>
                            </Grid> */}
                            {/* <Grid item pl={3}>
                                    <SearchBar />
                                </Grid> */}

                        </Grid>
                        <Divider />
                        <ClientTripsTable />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Container>
}

export default ClientPage;