import React, { useEffect, useState } from "react";
import { calculateIdleTime, formatDateFull } from "../utils/dateUtils";
import { findCheckpointColor } from "../utils/checkpointsUtils";
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Divider, Box, Typography, Button, ListItem } from "@mui/material";
import "../styles/TruckList.css";

const TruckListItem = ({ item, handleSelectTruck, selectedTruck }) => {
  const { routes, truck, last_route } = item;
  const hasRoutes = routes.length > 0;
  const sortedRoutes = hasRoutes ? routes.sort((a, b) =>
    new Date(a.load_date) - new Date(b.load_date)) : [];
  const lastCheckpointName = hasRoutes ? sortedRoutes[0].last_checkpoint.name : null;
  const backgroundColor = hasRoutes ? findCheckpointColor(lastCheckpointName) : "red";
  const firstRoute = hasRoutes ? routes[0] : null;

  const handleClick = () => { handleSelectTruck(truck) };


  if (!hasRoutes) {
    const lastCheckpointDate = last_route.checkpoints[last_route.checkpoints.length - 1].date;
    return (
      <Box className="truck-list-item" onClick={handleClick} style={{
        backgroundColor: selectedTruck && selectedTruck.truck._id === truck._id ? '#e996a6' : '',
      }}>
        <Box display={'flex'} justifyContent={'space-between'} paddingBottom={'3px'}>
          <Typography fontFamily={'Roboto'} fontSize={'12px'} paddingLeft={"3px"}>
            {last_route.driver.last_name} {last_route.driver.first_name.slice(0, 1) + "."}
          </Typography>
          <Button
            disableElevation
            sx={{
              backgroundColor,
              padding: '0px 5px 0px 5px',
              borderRadius: '5px',
              textTransform: 'none',
              color: 'black'
            }}
          >
            <Typography fontFamily={'Roboto'} fontSize="12px">
              {lastCheckpointName ? <></> : <>Простій</>}
            </Typography>
          </Button>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography paddingLeft={"3px"} width={'10%'}>
            <b>{truck.short_number}</b>
          </Typography >
          <Box display={'flex'} justifyContent={'space-evenly'} width={'90%'}>
            <Typography fontFamily={'Roboto'} variant="subtitle2" component="b">
              Простій {calculateIdleTime(lastCheckpointDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="truck-list-item" onClick={handleClick}
      style={{
        backgroundColor: selectedTruck && selectedTruck.truck._id === truck._id ? '#e996a6' : '',
      }}>
      <Box display={'flex'} justifyContent={'space-between'} paddingBottom={'3px'}>
        <Typography fontFamily={'Roboto'} fontSize={'12px'} paddingLeft={"3px"}
        >
          {firstRoute.driver.last_name} {firstRoute.driver.first_name.slice(0, 1) + "."}
        </Typography>
        <Button
          disableElevation
          sx={{
            backgroundColor,
            padding: '0px 5px 0px 5px',
            borderRadius: '5px',
            textTransform: 'none',
            color: 'black'
          }}
        >
          <Typography fontFamily={'Roboto'} fontSize="12px">
            {lastCheckpointName}
          </Typography>
        </Button>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography paddingLeft={"3px"} width={'10%'}>
          <b>{truck.short_number}</b>
        </Typography>
        <Box display={'flex'} justifyContent={'space-evenly'} width={'90%'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle2" component="b">{firstRoute.point_load.city ? firstRoute.point_load.city : firstRoute.point_load.region}</Typography>
            <Typography fontFamily={'Roboto'} variant="body2" fontSize={12}>{formatDateFull(firstRoute.load_date)}</Typography>
          </Box>
          <FaLongArrowAltRight />
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle2" component="b">{firstRoute.point_unload.city ? firstRoute.point_unload.city : firstRoute.point_unload.region}</Typography>
            <Typography fontFamily={'Roboto'} variant="body2" fontSize={12}>{formatDateFull(firstRoute.unload_date)}</Typography>
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default TruckListItem;
