import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem } from '@mui/material';
import { FaRegDotCircle, FaChevronCircleRight } from 'react-icons/fa';
import { checkpointsList } from '../config';
import { addCheckpoint } from '../api/TripsApi';

const AddCheckpoint = ({ trip, onAddCheckpoint }) => {
  const [open, setOpen] = useState(false);
  const toggleDialog = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={toggleDialog}
        title='Змінити статус'
      >
        <FaRegDotCircle />
      </Button>
      <AddCheckpointDialog
        onAddCheckpoint={onAddCheckpoint}
        trip={trip}
        toggleDialog={toggleDialog}
        open={open}
      />
    </div>
  );
};

const AddCheckpointDialog = ({ onAddCheckpoint, trip, toggleDialog, open }) => {
  const checkpoints = checkpointsList;
  const [currentCheckpoint, setCurrentCheckpoint] = useState(trip.checkpoints[trip.checkpoints.length - 1])
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(() => {
    const selectedStage = checkpoints.find(stage =>
      stage.checkpoints.some(checkpoint => checkpoint.name === currentCheckpoint.name));
    return selectedStage.checkpoints.find(checkpoint =>
      checkpoint.name === currentCheckpoint.name);
  });

  const handleAddCheckpoint = async () => {
    if (selectedCheckpoint) {
      const route = await addCheckpoint(trip, selectedCheckpoint);
      onAddCheckpoint(route);
      setSelectedCheckpoint(null);
    }
  };

  const handleCheckpointPress = (checkpoint) => {
    setSelectedCheckpoint(checkpoint);
  };


  return (<Dialog open={open} onClose={toggleDialog} >
    <DialogTitle>Зміна статуса</DialogTitle>
    <DialogContent>
      <List>
        {checkpoints.map((section) => (
          <div key={section.id}>
            <h5>
              <FaChevronCircleRight /> {section.stage}
            </h5>
            {section.checkpoints.map((checkpoint) => (
              <ListItem
                key={checkpoint.id}
                button
                selected={selectedCheckpoint?.id === checkpoint.id}
                onClick={() => handleCheckpointPress(checkpoint)}
              >
                {checkpoint.name}
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={(event) => { toggleDialog(event); handleAddCheckpoint() }}>
        Зберегти
      </Button>{' '}
      <Button variant="contained" color="secondary" onClick={toggleDialog}>
        Відмінити
      </Button>
    </DialogActions>
  </Dialog>);
}
export { AddCheckpoint, AddCheckpointDialog };
