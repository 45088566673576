import React, { useState } from "react";
import { Grid, Typography, Button, ButtonGroup, Chip } from '@mui/material';
import { FaEdit, FaRegEye } from "react-icons/fa";
import { getUserPassword, updateUser } from "../../api/UsersApi";
import "../../styles/UserTable.css";
import EditUserDialog from "./EditUserDialog";

const UsersTableRow = ({ user }) => {

  const [userData, setUserData] = useState(user);
  const [userPassword, setUserPassword] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleShowPassword = async () => {
    setUserPassword(await getUserPassword(user._id));
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveUser = (formData) => {
    setUserData(formData);
    updateUser(formData);
  };

  return (
    <Grid
      container
      p={1}
      className={`usersTableRow ${userData.isDeleted ? 'deletedUserRow' : ''}`}
      alignItems={'center'}
    >
      <Grid item sm={3}>
        <Typography variant="body1">
          {userData.last_name + " " + userData.first_name + " " + userData.surname}
        </Typography>
      </Grid>
      <Grid item sm={1} textAlign={'center'}>
        <Typography>{userData.role}</Typography>
      </Grid>
      <Grid item sm={2} textAlign={'center'}>
        <Typography>{userData.phone}</Typography>
      </Grid>
      <Grid item sm={1} textAlign={'center'}>
        {userPassword ? (
          <Typography>{userPassword}</Typography>
        ) : (
          <Button variant="contained" onClick={handleShowPassword}>
            <FaRegEye />
          </Button>
        )}
      </Grid>
      <Grid item sm={3} textAlign={'center'}>
        <Grid container direction="column">
          {userData.clients.map((client) => (
            <Chip key={client._id} label={client.name} size="small" color="primary" />
          ))}
        </Grid>
      </Grid>
      <Grid item sm={1} textAlign={'center'}>
        <Typography variant="body1">{userData.driverLicense}</Typography>
      </Grid>
      <Grid item sm={1} textAlign={'right'}>
        <ButtonGroup>
          <Button variant="contained" onClick={handleOpenEditDialog}>
            <FaEdit />
          </Button>
          <EditUserDialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            user={userData}
            onSave={handleSaveUser}
          />
         
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default UsersTableRow;
