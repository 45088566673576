import axios from 'axios';
import { fetchRoutesByTruckId } from './TrucksApi';
import { formatDate } from '../utils/dateUtils';
import { serverUrl } from '../config';
import { formatAddress } from '../utils/utils';

async function getLastGpsPositionVehicle(truck, trailer) {
  let point;
  let temperature;
  let data;
  let lastTime;
  let latitude; 
  let longitude;
  if (truck.gpsId || trailer.gpsId) {
    data = await fetchLastGpsPosition(truck._id, trailer._id);
    latitude = data.latitude;
    longitude = data.longitude;
    point = (await fetchLocationData(data.latitude, data.longitude));
    temperature = data.temperature;
    lastTime = data.date;
  } else {
    let vehicleTrips = await fetchRoutesByTruckId(truck._id);
    if (vehicleTrips) {
      let foundCheckpoint = false; // Флаг, який сигналізує, чи був знайдений чекпоінт

      // Перебираємо рейси в зворотньому порядку
      for (let i = vehicleTrips.length - 1; i >= 0; i--) {
        const trip = vehicleTrips[i];
        if (trip.checkpoints && trip.checkpoints.length > 0) {
          // Перевіряємо чекпоінти в поточному рейсі
          for (let j = trip.checkpoints.length - 1; j >= 0; j--) {
            const checkpoint = trip.checkpoints[j];
            if (checkpoint.longitude && checkpoint.longitude !== '0') {
              // Знайдено останній чекпоінт з вказаними координатами
              point = (await fetchLocationData(checkpoint.latitude, checkpoint.longitude));
              foundCheckpoint = true;
              lastTime = checkpoint.date;
              break; // Вийти з циклу чекпоінтів
            }
          }
        }
        if (foundCheckpoint) {
          break; // Вийти з циклу рейсів, якщо був знайдений чекпоінт
        }
      }
    }

  }

  return {temperature, date: formatDate(lastTime), address: formatAddress(point.address), 
    displayAddress: point.display_name, latitude, longitude};

}

async function fetchLastGpsPosition(truckId, trailerId) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/gps/getLastPosition/?truck_id=${truckId}&trailer_id=${trailerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function fetchLastGpsPositionForTrip(trip_id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/gps/getLastPositionForTrip/?trip_id=${trip_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function fetchTrackPositionsForTrip(trip_id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/gps/getTrackPositionsForTrip/?trip_id=${trip_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function fetchLocationData(lat, lon) {
  try {
    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    return null;
  }
}

export { fetchLocationData, getLastGpsPositionVehicle, fetchTrackPositionsForTrip, fetchLastGpsPositionForTrip }