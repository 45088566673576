import React, { useEffect, useState } from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete } from '@mui/material';
import "../../styles/UserTable.css";
import { fetchClients } from "../../api/ClientsApi";

const EditUserDialog = ({ open, onClose, user, onSave }) => {
    const [formData, setFormData] = useState(user);
    const [clients, setClients] = useState([]);
  
    const handleFieldChange = (field, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    };
  
    const handleSave = () => {
      onSave(formData);
      onClose();
    };
  
    const fetchClientsArray = async () =>{
      setClients(await fetchClients());
    }
    useEffect(() => {
      if (open) {
        fetchClientsArray();
      }
    }, [open]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Редагування користувача</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {[
              { field: 'last_name', label: 'Прізвище' },
              { field: 'first_name', label: "Ім'я" },
              { field: 'surname', label: 'По-батькові' },
            ].map((item) => (
              <Grid item xs={4} key={item.field}>
                <TextField
                  value={formData[item.field]}
                  variant="standard"
                  label={item.label}
                  fullWidth
                  onChange={(e) => handleFieldChange(item.field, e.target.value)}
                />
              </Grid>
            ))}
          </Grid>
  
          <Grid container spacing={2} pt={2}>
            {[
              { field: 'phone', label: 'Телефон' },
              { field: 'code', label: 'Пароль' },
            ].map((item) => (
              <Grid item xs={6} key={item.field}>
                <TextField
                  value={formData[item.field]}
                  variant="standard"
                  label={item.label}
                  fullWidth
                  onChange={(e) => handleFieldChange(item.field, e.target.value)}
                />
              </Grid>
            ))}
          </Grid>
  
          <Grid container spacing={2} pt={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="isDeleted-label">Видалений</InputLabel>
                <Select
                  labelId="isDeleted-label"
                  id="isDeleted"
                  value={formData.isDeleted}
                  label="Видалений"
                  fullWidth
                  onChange={(e) => handleFieldChange('isDeleted', e.target.value)}
                >
                  <MenuItem value={false}>Ні</MenuItem>
                  <MenuItem value={true}>Так</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="role-label">Роль</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  value={formData.role}
                  label="Роль"
                  fullWidth
                  onChange={(e) => handleFieldChange('role', e.target.value)}
                >
                  <MenuItem value="driver">Водій</MenuItem>
                  <MenuItem value="logist">Логіст</MenuItem>
                  <MenuItem value="client">Клієнт</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
  
          <Grid container spacing={2} pt={2}>
            <Grid item xs={6}>
              <TextField
                value={formData.driverLicense}
                variant="standard"
                label="Посвідчення водія"
                fullWidth
                onChange={(e) => handleFieldChange('driverLicense', e.target.value)}
              />
            </Grid>
          </Grid>
  
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="clients"
                fullWidth
                multiple
                options={clients}
                getOptionLabel={(option) => option.name}
                value={formData.clients}
                onChange={(_, newValue) => handleFieldChange('clients', newValue)}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Група клієнтів"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Закрити
          </Button>
          <Button onClick={handleSave} color="primary">
            Зберегти
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default EditUserDialog;