import React, { useEffect } from "react";
import { FaTruck, FaArrowCircleDown, FaArrowCircleUp, FaTrailer, FaLocationArrow, FaSearchLocation, FaTag, FaRuler, FaTags, FaMoneyCheck, FaComment } from 'react-icons/fa';

import { findCheckpointColor } from "../utils/checkpointsUtils";
import CheckpointsList from "./CheckpointList";
import Person from "./Person";
import EditTripModal from "./EditTripModal";
import AddCheckpoint from "./AddCheckpoint";
import { formatDateFull, formatDateWithoutYear } from "../utils/dateUtils";
import { Typography, List, ListItem, ListItemText, Box, Divider, Button } from "@mui/material";

function TruckTripsList({ selectedTruck, setSelectedTrip, selectedTrip, setShowCurrentPosition }) {




    return (
        <Box height={'100%'} overflow={'hidden'}>
            <Box display={'flex'}>
                <Typography variant="h5" style={{ padding: '10px' }} color={'primary'}>Рейси авто {selectedTruck.truck.short_number}</Typography>
            </Box >
            <List style={{ overflow: 'auto', height: 'calc(100% - 40px)' }}>
                {selectedTruck.routes.map((trip) => (
                    // <ListItem
                    //     disablePadding
                    //     alignItems="flex-start"
                    //     key={trip._id}
                    //     className="trip-list-item"
                    //     style={{
                    //         display: 'block',
                    //         backgroundColor: selectedTrip._id === trip._id ? 'gray' : 'default',
                    //     }}
                    // >
                    <ListItem
                        disablePadding
                        alignItems="flex-start"
                        key={trip._id}
                        className="trip-list-item"
                        style={{
                            display: 'block',
                            backgroundColor: selectedTrip && selectedTrip._id === trip._id ? '#e996a6' : 'white',
                        }}
                    >




                        <ListItemText style={{ margin: 0 }} onClick={() => {setSelectedTrip(trip); setShowCurrentPosition(false)}}

                            primary={
                                <Button
                                    disableElevation
                                    sx={{
                                        backgroundColor: findCheckpointColor(trip.checkpoints[trip.checkpoints.length - 1].name),
                                        padding: '0px 5px 0px 5px',
                                        borderRadius: '5px',
                                        textTransform: 'none',
                                        color: 'black'
                                    }}
                                >
                                    <Typography fontFamily={'Roboto'} fontSize="12px">
                                        {trip.checkpoints[trip.checkpoints.length - 1].name}
                                    </Typography>
                                </Button>
                            }
                            secondary={
                                <Typography variant="caption">
                                    <div className='trip-card-point' >
                                        <span>{trip.point_load.city? trip.point_load.city : trip.point_load.region}</span>
                                        <small>{formatDateWithoutYear(trip.load_date)}</small>
                                        {/* <Typography variant="body2"><FaLongArrowAltRight /></Typography> */}

                                    </div>

                                    <div className='trip-card-point' >
                                        <span>{trip.point_unload.city? trip.point_unload.city : trip.point_unload.region }</span>
                                        <small>{formatDateWithoutYear(trip.unload_date)}</small>
                                    </div>
                                </Typography>
                            }
                        />
                        <Divider />
                    </ListItem>

                ))}
            </List>
        </Box>
    );
};




export default TruckTripsList;
