import { serverUrl } from "../config";
import axios from "axios";

  const fetchTrailersPaginated = async (page, limit) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${serverUrl}/api/trailers/paginatedAll?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { totalPages: response.data.totalPages, vehicles: response.data.trailers };
    } catch (error) {
      console.log(error);
      return null;
    }
  };


  const updateTrailer = async (trailer) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(`${serverUrl}/api/trailers`, trailer, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };



export {updateTrailer, fetchTrailersPaginated}