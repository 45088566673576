import React from "react";
import "../styles/TruckList.css"
import { Grid, Typography, Paper } from '@mui/material';
import EditTripModal from "../components/EditTripModal";
import {AddCheckpoint} from "../components/AddCheckpoint";
import TripDetails from "./TripDetails";

function TripDetailsBlock({
  selectedTrip,
  handleEditTrip,
  handleAddCheckpoint,
  selectedTruck,
  showCheckpoints,
  showIdle }) {

  return (
    <Paper>
      <Typography variant="h5"
        style={{
          color: '#e996a6',
          padding: '10px',
          borderRadius: '10px'
        }}>
        Інформація по рейсу
      </Typography>
      {selectedTrip &&
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems={'center'}
            style={{ padding: '0px' }}
          >
            <Grid>
              <Typography variant="body2" component="div">
                # {selectedTrip.route_id}
              </Typography>
            </Grid>
            <Grid item>
              <EditTripModal trip={selectedTrip} onUpdateTrip={handleEditTrip} />
            </Grid>
            <Grid item>
              <AddCheckpoint trip={selectedTrip} onAddCheckpoint={handleAddCheckpoint} />
            </Grid>
          </Grid>

          <TripDetails
            selectedTrip={selectedTrip}
            selectedTruck={selectedTruck}
            showCheckpoints={showCheckpoints}
            showIdle={showIdle}
          />
        </>
      }
    </Paper>
  );
}

export default TripDetailsBlock;
