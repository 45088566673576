import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { Typography, TextField, Button, Container, Paper, Box, CssBaseline } from '@mui/material';

// Імпортуйте ваш SVG логотип як компонент зображення
import YourLogo from '../assets/ico.svg'; // Замініть на правильний шлях до вашого SVG логотипу

const Login = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(code);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh', // Встановлюємо висоту на весь екран
        display: 'flex',
        flexDirection: 'column', // Змінюємо напрямок відображення на вертикальний
        justifyContent: 'center', // Вирівнювання по горизонталі
        alignItems: 'center', // Вирівнювання по вертикалі
      }}
    >
      {/* Додайте ваш логотип тут з верхнім відступом */}
      <img
        src={YourLogo}
        alt="Ваш логотип"
        style={{
          width: '300px',
          marginBottom: '20px',
          marginTop: '-300px', // Задайте верхній відступ (за бажанням, від'ємне значення для підняття вгору)
        }}
      />

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper elevation={3}>
          <Box p={3}>
            <Typography variant="h4">Вхід</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit}>
              <TextField
                type="password"
                label="Пароль"
                variant="outlined"
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Увійти
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
